import AbstractAccessGetter, { AccessRights } from "../AbstractAccessGetter";

const scanapps = [
  "INV.Q",
  "PICKER.E",
  "IT.BE",
  "PHYS.BE",
  "PHYS.BE2",
  "ITSO.BE",
  "EMPTIME.BE3",
  "EMPTIME.BE4",
  "SHIP.BE",
  "SHIP.CBE",
  "SHIP.CBE2",
];

export default class ScanAccessGetter extends AbstractAccessGetter {
  constructor() {
    super();
  }

  getAccess(path: string, commands: string[]): boolean {
    const webName = this.getWebCommand(path);
    if (webName) {
      return this.hasCommand(webName, commands);
    } else {
      return commands.filter((item) => scanapps.includes(item)).length > 0;
    }
  }

  getAccessRights(
    path: string,
    commands_rights: { [key: string]: AccessRights },
  ): AccessRights {
    const webName = this.getWebCommand(path);
    if (webName) {
      return commands_rights[this.getWebCommand(path)] || new AccessRights();
    } else {
      return Object.keys(commands_rights).filter((item) =>
        scanapps.includes(item),
      ).length > 0
        ? {
            add: true,
            delete: true,
            change: true,
            view: true,
            hide_fields: [],
            block_fields: [],
          }
        : new AccessRights();
    }
  }

  getWebCommand(path: string): string {
    const pathList = path.split("/");

    let webName = "";
    if (pathList[2] == "inventory") webName = "INV.Q";
    else if (pathList[2] == "picking") webName = "PICKER.E";
    else if (pathList[2] == "transfer") webName = "IT.BE";
    else if (pathList[2] == "shopTransaction") webName = "ST.BE";
    else if (pathList[2] == "count") webName = "PHYS.BE";
    else if (pathList[2] == "recount") webName = "PHYS.BE2";
    else if (pathList[2] == "order") webName = "ITSO.BE";
    else if (pathList[2] == "startJob") webName = "EMPTIME.BE3";
    else if (pathList[2] == "endJob") webName = "EMPTIME.BE4";
    else if (pathList[2] == "shipment") webName = "SHIP.BE";
    else if (pathList[2] == "pick") webName = "SHIP.CBE";
    else if (pathList[2] == "pack") webName = "SHIP.CBE2";
    else if (pathList[2] == "woCompletion") webName = "ST.E3";

    return webName;
  }
}
