import Utils from "@/utility/utils";
import { map, merge, mergeWith } from "lodash";
import { AccessRights } from "./accessControl/AbstractAccessGetter";

export interface FdictResponse {
  fdict_items: Array<Fdict>;
  BlockSize?: string;
  total_records_found?: string;
}
export interface FdictState {
  fdicts: Array<Fdict>;
  fdictMap: FdictMap;
}

export interface FdictMap {
  [key: string]: FieldMap;
}

export interface FieldMap {
  [key: string]: Field;
}

export interface Fdict {
  file_name: string;
  field_no_items: Array<Field>;
  correl_field_no_items: Array<Correlative>;
  allow_history?: string;
  file_title?: string;
  program_name?: string;
  show_lookup_lst?: string;
  lookup_name_items?: Array<any>; // Create typescript interface for this
  file_view_name_items?: Array<any>; // Create typescript interface for this
}

export interface Field {
  field_no: string;
  control_field?: string;
  conv?: string;
  dict_name: string;
  multi_line?: string;
  len?: string;
  required?: string;
  reference_file?: string;
  desc_items: Array<Desc>;
  web_required?: string;
  valid_value_items?: Array<ValidEntry>;
  default_selection?: string;
  custom_label?: string;
  custom_field?: string;
  display_name?: string;
  json_name: string;
  boolean?: string;
  data_only?: string;
  read_only?: string;
  web_validate?: string;
  just?: string;
  index?: string;
  ignore_change?: string;
  structure?: string;
  single_column_dropdown?: string;
}

export interface Correlative {
  correl_field_no?: string;
  correl_desc?: string;
  corr_ctrl_fld?: string;
  correl_dict_id: string;
  correlative?: string;
  correl_just?: string;
  correl_len?: string;
  correl_rw_ok?: string;
  correl_conv?: string;
  correl_index?: string;
  correl_type?: string;
  correl_multi_li?: string;
}

export function isFieldHidden(
  field: Field,
  accessRights: AccessRights,
): boolean {
  return accessRights?.hide_fields?.includes(field.dict_name);
}

export function isFieldReadOnly(
  field: Field,
  accessRights: AccessRights,
): boolean {
  return (
    Utils.booleanCheck(field?.read_only) ||
    accessRights?.block_fields?.includes(field.dict_name)
  );
}

export enum ConversionTypes {
  DATE = "D",
  NUMERIC_NO_JUSTIFICATION = "MD",
  NUMERIC_LEFT = "ML",
  NUMERIC_RIGHT = "MR",
}

export function isDictNumeric(field: Field): boolean {
  return (
    checkDictType(field, ConversionTypes.NUMERIC_LEFT) ||
    checkDictType(field, ConversionTypes.NUMERIC_NO_JUSTIFICATION) ||
    checkDictType(field, ConversionTypes.NUMERIC_RIGHT)
  );
}

export function isImageType(field: Field): boolean {
  return field.dict_name.toLowerCase().includes("image");
}

export function isDictDateType(field: Field): boolean {
  return checkDictType(field, ConversionTypes.DATE);
}

export function isDictMultiLine(field: Field): boolean {
  return Utils.booleanCheck(field.multi_line);
}

export function dictHasValidValues(field: Field): boolean {
  return !!field.valid_value_items && field.valid_value_items.length > 0;
}

export function checkDictType(field: Field, type: ConversionTypes): boolean {
  if (!field.conv) {
    return false;
  }
  return field.conv?.toUpperCase().startsWith(type);
}

export function isFieldCustom(field: Field): boolean {
  return Utils.booleanCheck(field?.custom_field);
}

export function createFieldMap(fields: Field[]): FieldMap {
  const fieldMap = {} as FieldMap;
  fields.forEach((field) => {
    fieldMap[field.field_no] = field;
    fieldMap[field.dict_name] = field;
  });
  return fieldMap;
}

export function convertCorrelativesToField(correls: Correlative[]): Field[] {
  return correls.map((correl, index) => {
    return {
      field_no: `CORREL-${index + 1}`,
      conv: correl.correl_conv,
      dict_name: correl.correl_dict_id,
      multi_line: correl.correl_multi_li,
      len: correl.correl_len,
      required: "N",
      read_only: "Y",
      display_name: Utils.formatDictionaryName(correl.correl_dict_id),
      json_name: correl.correl_dict_id.toLowerCase().replaceAll(".", "_"),
      control_field: correl.corr_ctrl_fld,
    } as Field;
  });
}

export function combineFdict(
  file_name: string,
  standard: FdictResponse,
  fieldMap: FieldMap,
) {
  const fdict = standard.fdict_items.find((f) => f.file_name == file_name);

  if (!fdict) {
    return;
  }

  const standardMap = createFieldMap(fdict.field_no_items);

  map(standardMap, (field, key) => {
    if (!(key in fieldMap)) {
      fieldMap[key] = field;
    } else {
      const existingField = fieldMap[key];
      if (existingField) {
        fieldMap[key] = merge(field, existingField);
      }
    }

    if (!fieldMap[key].display_name) {
      fieldMap[key].display_name = Utils.formatDictionaryName(
        fieldMap[key].dict_name,
      );
    }

    if (!fieldMap[key].json_name) {
      fieldMap[key].json_name = fieldMap[key].dict_name
        .toLowerCase()
        .replaceAll(".", "_");
    }
  });
}

export interface ValidEntry {
  valid_value: string;
  valid_desc?: string;
}

export interface Desc {
  desc: string;
}
