import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import {
  FdictState,
  Fdict,
  Field,
  FdictResponse,
  combineFdict,
  createFieldMap,
  convertCorrelativesToField,
} from "@/types/fdict";
import FileDictionary from "@/services/FileDictionaryService";
import store from "..";
import Utils from "@/utility/utils";
import { FDICT_DEFAULT } from "@/utility/fdicts/fdictDefault";

const namespaced = true;
const FdictService = new FileDictionary(process.env.VUE_APP_ABSTRACTION_API);

export const state: FdictState = {
  fdicts: [],
  fdictMap: {},
};

export const getters: GetterTree<FdictState, RootState> = {
  getFdicts: (state) => {
    return state.fdicts;
  },
  getFdict: (state) => (id: string) => {
    return state.fdicts.find((fdict) => fdict.file_name === id);
  },
  getFdictMap: (state) => {
    return state.fdictMap;
  },
  customFields:
    (state, getters) =>
    (id: string, control_field = null): Field[] => {
      if (!(id in state.fdictMap)) {
        return [];
      }

      const fdictEntry = state.fdicts.find((f) => f.file_name === id);
      if (!fdictEntry) {
        return [];
      }

      const customFieldIds = fdictEntry.field_no_items
        .filter(
          (field) =>
            ((+field.field_no >= 80 && +field.field_no <= 100) ||
              field.web_required === "Y" ||
              field.custom_field === "Y") &&
            field.control_field == control_field,
        )
        .map((f) => f.field_no);

      return getters.getFields(id, customFieldIds) as Field[];
    },
  fieldExists:
    (state) =>
    (id: string, field_name: string): boolean => {
      if (id in state.fdictMap && field_name in state.fdictMap[id]) {
        return true;
      }
      return false;
    },
  correlativeExists:
    (state) =>
    (id: string, correlative: string): boolean => {
      const fdict = state.fdicts.find((fdict) => fdict.file_name === id);
      if (fdict && fdict.correl_field_no_items) {
        return fdict.correl_field_no_items.some(
          (item) => item.correl_dict_id === correlative,
        );
      } else {
        return false;
      }
    },
  getValidValuesAsMap:
    (state, getters) =>
    (id: string, field_no: string): Record<string, string> => {
      const field = getters.getField(id, field_no);
      if (field && field.valid_value_items) {
        return field.valid_value_items.reduce(
          (
            acc: Record<string, string>,
            {
              valid_value,
              valid_desc,
            }: { valid_value: string; valid_desc: string },
          ) => {
            acc[valid_value] = valid_desc;
            return acc;
          },
          {},
        );
      } else {
        return {};
      }
    },
  getField:
    (state) =>
    (id: string, field_no: string): Field => {
      const fields = state.fdictMap[id];
      if (!fields) {
        return {} as Field;
      }

      if (field_no in fields) {
        return fields[field_no];
      } else {
        return {} as Field;
      }
    },
  getFields:
    (state) =>
    (file: string, ids: string[]): Field[] => {
      const fieldMap = state.fdictMap[file];
      if (!fieldMap) {
        return [];
      }

      const fields: Field[] = [];

      ids.forEach((id) => {
        if (id in fieldMap) {
          fields.push(fieldMap[id]);
        }
      });

      return fields;
    },
  getFieldLabel:
    (state) =>
    (id: string, field_no: string, defaultLabel = ""): string => {
      if (!(id in state.fdictMap)) {
        return defaultLabel;
      }

      const fields = state.fdictMap[id];
      let field = null;
      if (field_no in fields) {
        field = fields[field_no];
      }

      if (!field) {
        return defaultLabel;
      }

      if (field && field.display_name) {
        return field.display_name;
      } else if (field && field.custom_label) {
        // This is deprecated, but may still be used in some places. Keeping for backward compatibility until integrations can make the move
        return field.custom_label;
      } else {
        return defaultLabel;
      }
    },
  controlFieldValues:
    (state, getters) =>
    (id: string, control_field: string): Field[] => {
      if (!(id in state.fdictMap)) {
        return [];
      }

      const fdictEntry = state.fdicts.find((f) => f.file_name === id);
      if (!fdictEntry) {
        return [];
      }

      const controlFieldIds = fdictEntry.field_no_items
        .filter((item) => item.control_field === control_field)
        .map((item) => item.field_no);

      const fields: Field[] = getters.getFields(id, controlFieldIds);

      return fields.map((item) => ({
        ...item,
        label: item.desc_items.map((desc) => desc.desc).join(" "),
        json_name: item.dict_name.toLowerCase().replaceAll(".", "_"),
      }));
    },
};

export const mutations: MutationTree<FdictState> = {
  ADD_FDICT(state, fdict: Fdict) {
    if (!fdict) return;

    fdict.field_no_items.forEach((field) => {
      if (!field.field_no) {
        // Edge case if the field is blank. Happens if a blank row is added
        return;
      }
      if (!field.json_name) {
        field.json_name = field.dict_name?.toLowerCase().replaceAll(".", "_");
      }
      if (!field.custom_field) {
        if (+field.field_no >= 80 && +field.field_no <= 100) {
          field.custom_field = "Y";
        } else {
          field.custom_field = "";
        }
      }
      if (!field.display_name) {
        field.display_name = Utils.formatDictionaryName(field.dict_name);
      }
    });

    const index = state.fdicts.findIndex(
      (item) => item.file_name === fdict.file_name,
    );

    const fields = fdict.field_no_items;

    state.fdictMap[fdict.file_name] = createFieldMap(fields);

    const fdictMap = state.fdictMap[fdict.file_name];

    if (fdictMap != undefined) {
      combineFdict(fdict.file_name, FDICT_DEFAULT, fdictMap);
    }

    if (index > -1) {
      state.fdicts[index] = fdict;
    } else {
      state.fdicts = [...state.fdicts, fdict];
    }
  },
  UPDATE_FDICT(state, fdict: Fdict) {
    const fields = fdict.field_no_items;

    const map = state.fdictMap[fdict.file_name];

    fields.forEach((field) => {
      if (field.field_no in map) {
        map[field.field_no] = { ...map[field.field_no], ...field };
        map[field.dict_name] = map[field.field_no];
      } else {
        map[field.field_no] = field;
        map[field.dict_name] = field;
      }
    });
  },
};

export const actions: ActionTree<FdictState, RootState> = {
  fetchFdict({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      let fdict = null;
      if (state.fdicts) {
        fdict = state.fdicts.find((fdict) => fdict.file_name === id);
      }
      if (fdict) {
        resolve(fdict);
      } else {
        const client = store.getters["session/getClient"];
        FdictService.getFdict(client, id)
          .then((response: FdictResponse) => {
            if (response.fdict_items) {
              commit("ADD_FDICT", response.fdict_items[0]);
              resolve(response.fdict_items[0]);
            } else {
              const defaultFdict = {
                file_name: id,
                field_no_items: [],
              };
              // Commit the default to the store and resolve
              commit("ADD_FDICT", defaultFdict);
              resolve(defaultFdict);
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  },
  updateFdict({ commit, state }, fdict: Fdict) {
    if (state.fdictMap[fdict.file_name]) {
      commit("UPDATE_FDICT", fdict);
    } else {
      commit("ADD_FDICT", fdict);
    }
  },
};
export const fdict: Module<FdictState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
