import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import ControlService from "@/services/ControlService";
import WorkOrderControlState from "@/types/controls/workOrder";

const namespaced = true;
const controlService = new ControlService();

export const state: WorkOrderControlState = {
  control_id: "",
  scheduling_group_items: [],
  prevent_excess_completions: "",
  end_Job_Stock_Transfer_Mode: "",
  oper_time_incr: "1",
  scheduling_tab_layout: "",
  gantt_status_items: [],
  substatus_status_items: [],
};

export const getters: GetterTree<WorkOrderControlState, RootState> = {
  getSchedulingGroupItems: (state) => {
    return state.scheduling_group_items;
  },
  getPreventExcessCompletions(state) {
    return state.prevent_excess_completions == "Y";
  },
  getEndJobStockTransferMode: (state) => {
    return state.end_Job_Stock_Transfer_Mode;
  },
  getCalendarTimeIncrement: (state) => {
    return parseInt(state.oper_time_incr);
  },
  getSchedulingTabLayout: (state) => {
    return state.scheduling_tab_layout;
  },
  getGanttStatusItems: (state) => {
    return state.gantt_status_items;
  },
  getSubStatusItems: (state) => {
    return state.substatus_status_items;
  },
  getFormatWorkOrderDisplayStatusList(state, getters, rootState, rootGetters) {
    const subStatusMap = new Map<string, Array<{ wo_substatus?: string }>>();
    state.substatus_status_items.forEach(
      (item: {
        wo_substatus_items?: Array<{ wo_substatus?: string }>;
        wo_substatus_status: string;
      }) => {
        if (item.wo_substatus_items) {
          subStatusMap.set(item.wo_substatus_status, item.wo_substatus_items);
        }
      },
    );
    const statusItems = rootGetters["fdict/getValidValuesAsMap"](
      "WO",
      "STATUS",
    );
    return Object.keys(statusItems).reduce(
      (acc: Array<{ id: string; label: string }>, key) => {
        const mainStatus = {
          id: statusItems[key],
          label: statusItems[key],
        };
        acc.push(mainStatus);

        // Check if there are sub-status items for the current key and add them
        if (subStatusMap.has(key)) {
          subStatusMap.get(key)!.forEach((subItem) => {
            if (subItem.wo_substatus) {
              acc.push({
                id: subItem.wo_substatus,
                label: subItem.wo_substatus,
              });
            }
          });
        }

        return acc;
      },
      [],
    );
  },
  getFormatWorkOrderStatusList(state, getters, rootState, rootGetters) {
    const statusItems = rootGetters["fdict/getValidValuesAsMap"](
      "WO",
      "STATUS",
    );
    return Object.keys(statusItems).reduce(
      (acc: Array<{ id: string; label: string }>, key) => {
        acc.push({
          id: key,
          label: statusItems[key],
        });
        return acc;
      },
      [],
    );
  },
};

export const mutations: MutationTree<WorkOrderControlState> = {
  SET_DATA(state, workOrderControl: WorkOrderControlState) {
    state.scheduling_group_items =
      workOrderControl.scheduling_group_items || [];
    state.control_id = workOrderControl.control_id;
  },
  SET_PREVENT_EXCESS_COMPLETIONS(state, value) {
    state.prevent_excess_completions = value;
  },
  SET_END_JOB_STOCK_TRANSFER_MODE(state, end_Job_Stock_Transfer_Mode) {
    state.end_Job_Stock_Transfer_Mode = end_Job_Stock_Transfer_Mode;
  },
  SET_OPERATION_TIME_INCREMENT(state, value) {
    state.oper_time_incr = value;
  },
  SET_SCHEDULING_TAB_LAYOUT(state, value) {
    state.scheduling_tab_layout = value;
  },
  SET_GANTT_STATUS_ITEMS(state, value) {
    state.gantt_status_items = value;
  },
  SET_SUBSTATUS_ITEMS(state, value) {
    state.substatus_status_items = value;
  },
};

export const actions: ActionTree<WorkOrderControlState, RootState> = {
  async fetchWorkOrderControl({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.control_id) {
        resolve(state);
      } else {
        controlService
          .getControl("WO", "WO.CONTROL", "CONTROL", "")
          .then((response: any) => {
            if (response.length > 0) {
              commit("SET_DATA", response[0]);
              commit(
                "SET_PREVENT_EXCESS_COMPLETIONS",
                response[0].prevent_excess_completions || "",
              );
              commit(
                "SET_END_JOB_STOCK_TRANSFER_MODE",
                response[0].end_job_stock_transfer_mode || "",
              );
              commit(
                "SET_OPERATION_TIME_INCREMENT",
                response[0].oper_time_incr || "1",
              );
              commit(
                "SET_SCHEDULING_TAB_LAYOUT",
                response[0].scheduling_tab_layout || "",
              );
              commit(
                "SET_GANTT_STATUS_ITEMS",
                response[0].gantt_status_items || [],
              );
              commit(
                "SET_SUBSTATUS_ITEMS",
                response[0].wo_substatus_status_items || [],
              );
              resolve(response[0]);
            } else {
              reject("No data returned");
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  },
};

export const woControl: Module<WorkOrderControlState, RootState> = {
  namespaced,
  getters,
  state,
  mutations,
  actions,
};
