import { Module, MutationTree, ActionTree, GetterTree } from "vuex";
import { RootState } from "@/types/state";

import AccountingReceivablesState from "@/types/state/accountingReceivables";
import Utils from "@/utility/utils";

import InvoiceService from "@/services/InvoiceService";
const service = new InvoiceService();

const namespaced = true;

export const state: AccountingReceivablesState = {
  receivables: {},
  isLoading: [],
  activeTab: 0,
  page: 1,
  rangeStart: 1,
  rangeEnd: 100,
  isLast: false,
  invoiceView: [],
  loadingInvoices: [],
  invoicesToPay: [],
  defaultDateRanges: [
    { label: "7", range: [getDate(0), getDate(7)] },
    { label: "8-14", range: [getDate(8), getDate(14)] },
    { label: "15-21", range: [getDate(15), getDate(21)] },
    { label: "22-28", range: [getDate(22), getDate(28)] },
  ],
  customDateRanges: [],
};

export const getters: GetterTree<AccountingReceivablesState, RootState> = {
  getReceivables: (state) => {
    return state.receivables;
  },
  getActiveTab: (state) => {
    return state.activeTab;
  },
  getDefaultDateRanges: (state) => {
    return state.defaultDateRanges;
  },
  getCustomDateRanges: (state) => {
    return state.customDateRanges;
  },
  getReceivablesByLabel: (state) => (label: string) => {
    return state.receivables[label];
  },
  getIsTabLoading: (state) => (tabLabel: string) => {
    return state.isLoading.includes(tabLabel);
  },

  getRange(state): number {
    return state.rangeEnd;
  },
  getPage(state): number {
    return state.page;
  },
  getLength: (state) => (tabLabel: string) => {
    return state.receivables[tabLabel] ? state.receivables[tabLabel].length : 0;
  },
  getInvoicesToPay(state): Array<any> {
    return state.invoicesToPay;
  },
  getLoadingInvoices(state) {
    return state.loadingInvoices;
  },
  getDefaultTabsLength: (state) => {
    return state.defaultDateRanges.length;
  },
  getTabsLength: (state) => {
    return state.defaultDateRanges.length + state.customDateRanges.length;
  },
};

export const mutations: MutationTree<AccountingReceivablesState> = {
  ADD_ID_LOADING(state, id) {
    state.loadingInvoices.push(id);
  },
  REMOVE_ID_LOADING(state, id) {
    const index = state.loadingInvoices.indexOf(id, 0);
    if (index > -1) {
      state.loadingInvoices.splice(index, 1);
    }
  },
  SET_RECEIVABLES(state, { data, label }) {
    state.receivables[label] = data;
  },
  SET_ACTIVE_TAB(state, tab) {
    state.activeTab = tab;
  },
  ADD_CUSTOM_DATE_RANGE(state, range) {
    state.customDateRanges.push({ ...range, label: range.label.trim() });
  },
  UPDATE_CUSTOM_DATE_RANGE(state, range) {
    state.customDateRanges = state.customDateRanges.map((item) => {
      if (item.label === range.label) {
        return range;
      }
      return item;
    });
  },
  REMOVE_CUSTOM_DATE_RANGE(state, rangeLabel) {
    state.customDateRanges = state.customDateRanges.filter(
      (item) => item.label !== rangeLabel,
    );
    delete state.receivables[rangeLabel];
  },
  ADD_IS_LOADING(state, { tabLabel }) {
    state.isLoading.push(tabLabel);
  },
  REMOVE_IS_LOADING(state, { tabLabel }) {
    state.isLoading = state.isLoading.filter((label) => label !== tabLabel);
  },

  NEXT_RANGE(state) {
    // state.rangeStart += 100;
    state.rangeEnd += 100;
  },
  NEXT_PAGE(state, page) {
    state.page = page;
  },
  RESET_PAGE(state) {
    state.page = 1;
    state.rangeStart = 1;
    state.rangeEnd = 100;
  },
  PUSH_INCOIVE_TO_PAY(state, invoice) {
    state.invoicesToPay.push(invoice);
  },
  REMOVE_INVOICE_TO_PAY(state, invoice) {
    const filteredarray = state.invoicesToPay.filter((inv) => {
      return invoice.arId != inv.arId;
    });
    state.invoicesToPay = filteredarray;
  },
  CLEAR_INVOICESTOPAY(state) {
    state.invoicesToPay = [];
  },
};

export const actions: ActionTree<AccountingReceivablesState, RootState> = {
  setActiveTab({ commit }, tab) {
    commit("SET_ACTIVE_TAB", tab);
  },
  addCustomDateRange({ commit }, range) {
    commit("ADD_CUSTOM_DATE_RANGE", range);
  },
  updateCustomDateRange({ commit }, range) {
    commit("UPDATE_CUSTOM_DATE_RANGE", range);
  },
  removeCustomDateRange({ commit }, rangeLabel) {
    commit("REMOVE_CUSTOM_DATE_RANGE", rangeLabel);
  },

  async setReceivables(
    { commit, dispatch, state },
    {
      custId,
      dateRange,
      status,
      sortBy,
      Client,
      id,
      type,
      correls,
      label,
      addInvoices = false,
    },
  ) {
    commit("ADD_IS_LOADING", { tabLabel: label });
    let dateStart = undefined;
    let dateEnd = undefined;
    if (dateRange == null || dateRange.length === 0) {
      dateStart = dateEnd = undefined;
    } else if (dateRange[0] && dateRange[1]) {
      dateStart = Utils.formatDate(dateRange[0]);
      dateEnd = Utils.formatDate(dateRange[1]);
    } else if (dateRange[0]) {
      dateStart = Utils.formatDate(dateRange[0]);
    } else if (dateRange[1]) {
      dateEnd = Utils.formatDate(dateRange[1]);
    }
    const sortByString = "by due.date ";

    if (!addInvoices) {
      commit("RESET_PAGE");
    }
    service
      .setInvoices(
        state.rangeStart,
        state.rangeEnd,
        custId,
        Client,
        dateStart,
        dateEnd,
        status,
        sortByString,
        id,
        type,
        correls,
      )
      .then((response: any) => {
        commit("SET_RECEIVABLES", { data: response.ar_items, label });
        commit("NEXT_RANGE");
      })
      .catch((error: any) => {
        const notification = {
          type: "error",
          message: error.response.data.error || "Error fetching invoices",
        };
        dispatch("notification/add", notification, { root: true });
      })
      .finally(() => {
        commit("REMOVE_IS_LOADING", { tabLabel: label });
      });
  },
  addIsLoading({ commit }, tabLabel) {
    commit("ADD_IS_LOADING", { tabLabel });
  },
  removeIsLoading({ commit }, tabLabel) {
    commit("REMOVE_IS_LOADING", { tabLabel });
  },

  setNextPage({ commit }, page) {
    commit("NEXT_PAGE", page);
  },
  addInvoiceToPay({ commit, state }, invToPay) {
    commit("PUSH_INCOIVE_TO_PAY", invToPay);
  },
  removeInvoiceToPay({ commit }, invToRemove) {
    commit("REMOVE_INVOICE_TO_PAY", invToRemove);
  },
  clearInvoicesToPay({ state, commit }) {
    commit("CLEAR_INVOICESTOPAY");
  },

  async getInvoice({ commit, dispatch, state }, payload) {
    commit("ADD_ID_LOADING", payload.recordId);
    await service
      .getInvoice(payload.recordId, payload.Client)
      .then((resp: any) => {
        const bufferArray = Utils.base64ToArrayBuffer(resp);
        const blobStore = new Blob([bufferArray], {
          type: "application/pdf",
        });
        const data = window.URL.createObjectURL(blobStore);
        window.open(data, "_blank");
        commit("REMOVE_ID_LOADING", payload.recordId);
      })
      .catch((error) => {
        const notification = {
          type: "error",
          message: error,
        };
        dispatch("notification/add", notification, { root: true });
        commit("REMOVE_ID_LOADING", payload.recordId);
      });
  },
  addIdLoading({ commit }, id) {
    commit("ADD_ID_LOADING", id);
  },
  removeIdLoading({ commit }, id) {
    commit("REMOVE_ID_LOADING", id);
  },
};

export const accountingReceivables: Module<
  AccountingReceivablesState,
  RootState
> = {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};

function getDate(days: number): string {
  if (days < 1) {
    return new Date().toString();
  }
  const newDate = new Date();
  newDate.setDate(newDate.getDate() + days);

  return newDate.toString();
}
