import { Module, ActionTree, MutationTree, GetterTree } from "vuex";
import { RootState } from "@/types/state";

import WorkOrderState, {
  OpenedWorkOrder,
  WorkOrderObject,
  WorkOrderToAdd,
} from "@/types/state/workOrder";
import PickerService from "@/services/PickerService";
import WorkOrderService from "@/services/WorkOrderService";
import dayjs from "dayjs";
import { WorkOrder } from "@/types/state/production";

import { cloneDeep } from "lodash";

const namespaced = true;
const service = new PickerService(process.env.VUE_APP_ABSTRACTION_API);
const WOService = new WorkOrderService(process.env.VUE_APP_ABSTRACTION_API);
export const state: WorkOrderState = {
  wo_id: "",
  wo_date: "",
  part_no: "",
  wo_qty: "",
  qty_comp: "",
  status: "",
  wip_loc: "",
  rel_date: "",
  req_date: "",
  model_number: "",
  description: "",
  schedule_date: "",
  pick: null,
  oper_items: null,
  workOrderList: [],
  oldRecordList: [],
  orderToAdd: {} as WorkOrderToAdd,
  openedWorkOrders: [],
  activeTab: 0,
  itemsPerPage: 5,
  page: 1,
  firstRow: 0,
  rangeStart: 1,
  rangeEnd: 100,
  totalRecords: 0,
  sortBy: "wo.id",
  sortOrder: "DEC",
  filterValues: {},
  selectedScheduleGroup: "",
  selectedWorkOrders: [],
  scheduledWorkOrders: [],
  unscheduledWorkOrders: [],
  scheduleFromDate: dayjs().startOf("d").toDate(),
  scheduleToDate: dayjs().endOf("d").toDate(),
};

export const getters: GetterTree<WorkOrderState, RootState> = {
  getPicker(state) {
    return state.pick;
  },
  getWorkOrder(state) {
    return state;
  },
  getStatus(state) {
    return state.status;
  },
  getId(state) {
    return state.wo_id;
  },
  getOrderToAdd: (state) => {
    return state.orderToAdd;
  },
  getWorkOrders: (state) => {
    return state.workOrderList;
  },
  getOpenedWorkOrders: (state) => {
    return state.openedWorkOrders;
  },
  getActiveTab: (state) => {
    return state.activeTab;
  },
  getActiveWorkOrder: (state) => {
    if (state.activeTab - 1 in state.openedWorkOrders) {
      return state.openedWorkOrders[state.activeTab - 1];
    } else {
      return null;
    }
  },
  getPage(state): number {
    return state.page;
  },
  getRange(state): number {
    return state.rangeEnd;
  },
  getItemsPerPage(state): number {
    return state.itemsPerPage;
  },
  getTotalRecords(state): number {
    return state.totalRecords;
  },
  getSortBy(state): string {
    return state.sortBy;
  },
  getSortOrder(state): string {
    return state.sortOrder;
  },
  getSelectedScheduleGroup(state): string {
    return state.selectedScheduleGroup;
  },
  getScheduledWorkOrders(state): Array<WorkOrder> {
    return state.scheduledWorkOrders;
  },
  getSelectedWorkOrders(state): Array<WorkOrderObject> {
    return state.selectedWorkOrders;
  },
  getUnscheduledWorkOrders(state): Array<WorkOrder> {
    return state.unscheduledWorkOrders;
  },
  getSchedulingFromDate(state): Date {
    return state.scheduleFromDate;
  },
  getSchedulingToDate(state): Date {
    return state.scheduleToDate;
  },
};
export const mutations: MutationTree<WorkOrderState> = {
  SET_WO(state, wo) {
    state.wo_id = wo.wo_id;
    state.wo_date = wo.wo_date;
    state.part_no = wo.part_no;
    state.wo_qty = wo.wo_qty;
    state.qty_comp = wo.qty_comp;
    state.status = wo.status;
    state.wip_loc = wo.wip_loc;
    state.rel_date = wo.rel_date;
    state.req_date = wo.req_date;
    state.model_number = wo.model_number;
    state.description = wo.description;
    state.schedule_date = wo.schedule_date;
    state.oper_items = wo.oper_items;
  },
  SET_PICK(state, pick) {
    state.pick = pick;
    if (state.pick?.li_no_items != null) {
      state.pick.li_no_items.forEach((item) => {
        item.bin_number = "";
        item.pull_lot = "";
      });
    }
  },
  SET_ORDER_TO_ADD(state, order) {
    state.orderToAdd = order;
  },
  CLEAR_ORDER_TO_ADD(state) {
    state.orderToAdd = {} as WorkOrderToAdd;
  },
  SET_WORK_ORDERS(state, workOrders) {
    state.workOrderList = workOrders;
  },
  SET_TOTAL_RECORDS(state, totalRecords) {
    state.totalRecords = totalRecords;
  },
  UPDATE_WORK_ORDER(state, order) {
    const index = state.workOrderList.findIndex(
      (workOrder) => workOrder.wo_id === order.wo_id,
    );
    state.workOrderList[index] = order;
  },
  ADD_OLD_RECORD(state, workOrders) {
    state.oldRecordList.push(workOrders);
  },
  CLEAR_OLD_RECORDS(state) {
    state.oldRecordList = [];
  },
  ADD_OPENED_WORK_ORDER(state, data) {
    const workOrderObject: OpenedWorkOrder = {
      oldRecord: cloneDeep(data),
      record: data,
    };
    state.openedWorkOrders = [workOrderObject, ...state.openedWorkOrders];
  },
  UPDATE_ACTIVE_TAB(state, tabIndex) {
    state.activeTab = tabIndex;
  },
  NEXT_PAGE(state, page) {
    state.page = page;
  },
  SET_RANGE_START(state, rangeStart) {
    state.rangeStart = rangeStart;
  },
  SET_RANGE_END(state, rangeEnd) {
    state.rangeEnd = rangeEnd;
  },
  SET_SORT_BY(state, sortBy) {
    state.sortBy = sortBy;
  },
  SET_SORT_ORDER(state, sortOrder) {
    state.sortOrder = sortOrder;
  },
  SET_FILTER_VALUES(state, filterValues) {
    state.filterValues = filterValues;
  },
  REMOVE_OPENED_ORDER_TAB(state, order) {
    state.openedWorkOrders = state.openedWorkOrders.filter((item) => {
      return item.oldRecord.wo_id !== order.oldRecord.wo_id;
    });
  },
  SET_SELECTED_SCHEDULE_GROUP(state, selectedScheduleGroup) {
    state.selectedScheduleGroup = selectedScheduleGroup;
  },
  ADD_SELECTED_WORK_ORDER(state, workOrder) {
    const workOrderObject: WorkOrderObject = {
      oldRecord: cloneDeep(workOrder),
      record: workOrder,
    };
    state.selectedWorkOrders = [workOrderObject, ...state.selectedWorkOrders];
  },
  REMOVE_SELECTED_WORK_ORDER(state, id) {
    state.selectedWorkOrders = state.selectedWorkOrders.filter(
      (item) => item.record.wo_id !== id,
    );
  },
  REMOVE_UNSCHEDULED_WORK_ORDER(state, id) {
    state.unscheduledWorkOrders = state.unscheduledWorkOrders.filter(
      (item) => item.wo_id !== id,
    );
  },
  UPDATE_SELECTED_WORK_ORDERS(state, { record, oldRecord }) {
    const index = state.selectedWorkOrders.findIndex(
      (item) => item.record.wo_id === record.wo_id,
    );
    if (index >= 0) {
      state.selectedWorkOrders[index].record = record;
      if (oldRecord) {
        state.selectedWorkOrders[index].oldRecord = oldRecord;
      }
    } else {
      if (!oldRecord) {
        oldRecord = cloneDeep(record);
      }
      state.selectedWorkOrders.push({ oldRecord: oldRecord, record: record });
    }
  },
  SET_SCHEDULED_WORK_ORDERS(state, workOrders) {
    state.scheduledWorkOrders = workOrders;
  },
  UPDATE_SCHEDULED_WORK_ORDER(state, workOrder) {
    const index = state.scheduledWorkOrders.findIndex(
      (item) => item.wo_id === workOrder.wo_id,
    );
    if (index >= 0) {
      state.scheduledWorkOrders[index] = workOrder;
    } else {
      state.scheduledWorkOrders.push(workOrder);
    }
  },
  SET_SCHEDULE_FROM_DATE(state, scheduleFromDate) {
    state.scheduleFromDate = scheduleFromDate;
  },
  SET_SCHEDULE_TO_DATE(state, scheduleToDate) {
    state.scheduleToDate = scheduleToDate;
  },
  SET_UNSCHEDULED_WORK_ORDERS(state, workOrders) {
    state.unscheduledWorkOrders = workOrders;
  },
  SET_SELECTED_WORK_ORDERS(state, workOrders) {
    state.selectedWorkOrders = workOrders;
  },
};

export const actions: ActionTree<WorkOrderState, RootState> = {
  async fetchPick({ commit, dispatch, state }, { id, client }) {
    return new Promise((resolve, reject) => {
      service
        .getPicker(id, client)
        .then((response: any) => {
          commit("SET_PICK", response);
          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  },

  async fetchOrder({ commit, dispatch }, { id, client }) {
    return new Promise((resolve, reject) => {
      WOService.getOrder(id, client)
        .then((response: any) => {
          commit("SET_WO", response);
          resolve("Success");
        })
        .catch((error: any) => {
          reject("error");
        });
    });
  },
  async workorderSearch(
    { commit, state },
    {
      ScheduledWorkcenter,
      UnscheduledWorkOrders,
      Status,
      Workcenters,
      Correls,
    },
  ) {
    return new Promise((resolve, reject) => {
      WOService.searchWorkOrders({
        FilterValues: state.filterValues,
        SortBy: state.sortBy,
        SortOrder: state.sortOrder,
        RangeStart: state.rangeStart,
        RangeEnd: state.rangeEnd,
        ScheduledWorkcenter,
        UnscheduledWorkOrders,
        Status,
        Workcenters,
        Correls,
      })
        .then((response: any) => {
          commit("SET_WORK_ORDERS", response.wo_items);
          commit("CLEAR_OLD_RECORDS");
          commit("SET_TOTAL_RECORDS", parseFloat(response.total_records_found));
          resolve(response);
        })
        .catch((error: any) => {
          reject("error");
        });
    });
  },
  async fetchUnscheduledWorkOrders(
    { commit, state },
    {
      ScheduledWorkcenter,
      UnscheduledWorkOrders,
      Status,
      Workcenters,
      Correls,
    },
  ) {
    return new Promise((resolve, reject) => {
      WOService.searchWorkOrders({
        FilterValues: state.filterValues,
        SortBy: state.sortBy,
        SortOrder: state.sortOrder,
        ScheduledWorkcenter,
        UnscheduledWorkOrders,
        Status,
        Workcenters,
        Correls,
        RangeStart: state.rangeStart,
        RangeEnd: state.rangeEnd,
      })
        .then((response: any) => {
          commit("SET_UNSCHEDULED_WORK_ORDERS", response.wo_items);
          resolve(response);
        })
        .catch((error: any) => {
          reject("error");
        });
    });
  },
  async fetchScheduledWorkOrders(
    { commit, state },
    { OperationStartDate, OperationCompDate, Workcenters, Correls },
  ) {
    return new Promise((resolve, reject) => {
      WOService.searchWorkOrders({
        Workcenters,
        Correls,
        OperationCompDate,
        OperationStartDate,
        ScheduledWorkOrders: true,
      })
        .then((response: any) => {
          commit("SET_SCHEDULED_WORK_ORDERS", response.wo_items);
          resolve(response);
        })
        .catch((error: any) => {
          reject("error");
        });
    });
  },
  async fetchOrders(
    { commit, state },
    { ScheduledWorkcenter, Status, Workcenters, Correls },
  ) {
    return new Promise((resolve, reject) => {
      WOService.getOrders({
        FilterValues: state.filterValues,
        SortBy: state.sortBy,
        SortOrder: state.sortOrder,
        RangeStart: state.rangeStart,
        RangeEnd: state.rangeEnd,
        ScheduledWorkcenter,
        Status,
        Workcenters,
        Correls,
      })
        .then((response: any) => {
          commit("SET_WORK_ORDERS", response.wo_items);
          commit("CLEAR_OLD_RECORDS");
          commit("SET_TOTAL_RECORDS", parseFloat(response.total_records_found));
          resolve(response);
        })
        .catch((error: any) => {
          reject("error");
        });
    });
  },
  async putOrders({ commit, dispatch, state }, { records, oldRecords }) {
    // when old and new records are not sorted in the same way, putrecord is reporting changes in records
    records.sort((a: any, b: any) => a.wo_id - b.wo_id);
    oldRecords.sort((a: any, b: any) => a.wo_id - b.wo_id);
    const payload = {
      records,
      oldRecords,
    };
    return new Promise((resolve, reject) => {
      WOService.putOrders(payload)
        .then((response: any) => {
          if (response.response.status === "failed") {
            // pull the errors from the response
            // ToDo: should these be seperate notification?
            const errorMessages = response.response.details
              .filter((record: { error: any }) => record.error !== undefined)
              .map(
                (record: { error: any; recordId: any }) =>
                  record.recordId + " : " + record.error,
              );
            const output = errorMessages.join("<br/> ");
            dispatch(
              "notification/add",
              {
                message: output,
                type: "error",
              },
              { root: true },
            );
            reject({ success: false });
          } else {
            dispatch(
              "notification/add",
              {
                message: `Order(s) successfully updated.`,
                type: "success",
              },
              { root: true },
            );
          }
          commit("CLEAR_OLD_RECORDS");
          resolve(response);
        })
        .catch((error) => {
          dispatch(
            "notification/add",
            {
              message: `${error.message}.`,
              type: "error",
            },
            { root: true },
          );
          reject({ success: false });
        });
    });
  },
  async putSelectedWorkOrders({ commit, dispatch, state }) {
    const records = state.selectedWorkOrders.map(
      (workOrder) => workOrder.record,
    );
    const oldRecords = state.selectedWorkOrders.map(
      (workOrder) => workOrder.oldRecord,
    );
    records.sort((a: any, b: any) => a.wo_id - b.wo_id);
    oldRecords.sort((a: any, b: any) => a.wo_id - b.wo_id);
    const payload = {
      records,
      oldRecords,
    };
    return new Promise((resolve, reject) => {
      WOService.putOrders(payload)
        .then((response: any) => {
          const failures = response.response.details.filter(
            (record: { error: any }) => record.error,
          );
          const successes = response.response.details.filter(
            (record: { error: any }) => !record.error,
          );

          if (failures.length > 0) {
            // pull the errors from the response
            const errorMessages = failures.map(
              (record: { error: any; recordId: any }) => record.error,
            );
            const errorMessage = errorMessages.join("<br/> ");
            dispatch(
              "notification/add",
              {
                message: errorMessage,
                type: "error",
              },
              { root: true },
            );
          }

          if (successes.length > 0) {
            const successIds = successes.map(
              (record: { recordId: any }) => record.recordId,
            );
            dispatch(
              "notification/add",
              {
                message: `Work Order(s) ${successIds.join(
                  ",",
                )} successfully updated.`,
                type: "success",
              },
              { root: true },
            );
            successIds.forEach((id: any) => {
              const workOrder = state.selectedWorkOrders.find(
                (workOrder) => workOrder.record.wo_id === id,
              );
              if (workOrder) {
                commit(
                  "UPDATE_SCHEDULED_WORK_ORDER",
                  cloneDeep(workOrder?.record),
                );
              }
              commit("REMOVE_SELECTED_WORK_ORDER", id);
            });
          }
          resolve("Success");
        })
        .catch((error) => {
          dispatch(
            "notification/add",
            {
              message: `${error.message}.`,
              type: "error",
            },
            { root: true },
          );
          reject({ success: false });
        });
    });
  },
  async updateStoredOldOrders({ commit, dispatch, state }) {
    const newRecords = state.workOrderList.filter((order) =>
      state.oldRecordList.some((oldRecord) => oldRecord.wo_id === order.wo_id),
    );
    const payload = {
      records: newRecords,
      oldRecords: state.oldRecordList,
    };
    dispatch("putOrders", payload);
  },
  postPick({ commit, dispatch, state }, { client, pickRec }) {
    return new Promise((resolve, reject) => {
      service
        .putPicker(state.wo_id, client, pickRec)
        .then((response) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject("error");
        });
    });
  },
  setOrderToAdd({ commit }, order) {
    commit("SET_ORDER_TO_ADD", order);
  },
  clearOrderToAdd({ commit }) {
    commit("CLEAR_ORDER_TO_ADD");
  },
  async updateWorkOrder({ commit, state }, { order, oldRecord }) {
    const oldRecordExist = state.oldRecordList.find(
      (workOrder) => workOrder.wo_id === order.wo_id,
    );
    if (!oldRecordExist) {
      commit("ADD_OLD_RECORD", oldRecord);
    }
    commit("UPDATE_WORK_ORDER", order);
  },
  addWorkOrderToOpenedOrders({ commit, state }, data) {
    const isOpened = state.openedWorkOrders.findIndex(
      (item) => item.oldRecord.wo_id === data.wo_id,
    );
    if (isOpened < 0) {
      commit("ADD_OPENED_WORK_ORDER", data);
      commit("UPDATE_ACTIVE_TAB", 1);
    } else {
      commit("UPDATE_ACTIVE_TAB", isOpened + 1);
    }
  },
  removeOpenedOrderTab({ commit, state }, payload) {
    commit("REMOVE_OPENED_ORDER_TAB", payload);
    if (state.activeTab > 0) {
      commit("UPDATE_ACTIVE_TAB", 0);
    }
  },
  changeActiveTab({ commit }, tabIndex: number) {
    commit("UPDATE_ACTIVE_TAB", tabIndex);
  },
  setSelectedScheduleGroup({ commit }, selectedScheduleGroup) {
    commit("SET_SELECTED_SCHEDULE_GROUP", selectedScheduleGroup);
  },
  addSelectedWorkOrder({ commit }, workOrder) {
    commit("ADD_SELECTED_WORK_ORDER", workOrder);
  },
  removeSelectedWorkOrderById({ commit }, workOrderId) {
    commit("REMOVE_SELECTED_WORK_ORDER", workOrderId);
  },
  updateSelectedWorkOrder({ commit }, { record, oldRecord }) {
    commit("UPDATE_SELECTED_WORK_ORDERS", { record, oldRecord });
  },
  setSchedulingFromDate({ commit }, scheduleFromDate) {
    commit("SET_SCHEDULE_FROM_DATE", scheduleFromDate);
  },
  setSchedulingToDate({ commit }, scheduleToDate) {
    commit("SET_SCHEDULE_TO_DATE", scheduleToDate);
  },
  clearScheduledWorkOrders({ commit }) {
    commit("SET_SCHEDULED_WORK_ORDERS", []);
  },
  clearSelectedWorkOrders({ commit }) {
    commit("SET_SELECTED_WORK_ORDERS", []);
  },
};

export const workorder: Module<WorkOrderState, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
  getters,
};
