import { FdictResponse } from "@/types/fdict";

export const FDICT_DEFAULT: FdictResponse = {
  total_records_found: "4",
  BlockSize: "50",
  fdict_items: [
    {
      file_name: "SO",
      field_no_items: [
        {
          field_no: "0",
          conv: "MCU",
          dict_name: "SO.ID",
          desc_items: [
            {
              desc: "SO.Id",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "1",
          conv: "D2-",
          dict_name: "DATE",
          desc_items: [
            {
              desc: "Date",
            },
          ],
          just: "R",
          index: "N",
          required: "Y",
          json_name: "",
        },
        {
          field_no: "2",
          conv: "MCU",
          dict_name: "SOLD.TO",
          desc_items: [
            {
              desc: "Sold.to",
            },
          ],
          just: "L",
          index: "Y",
          required: "Y",
          json_name: "",
        },
        {
          field_no: "3",
          conv: "D2-",
          dict_name: "BOOK.DATE",
          desc_items: [
            {
              desc: "Book",
            },
            {
              desc: "Date",
            },
          ],
          just: "R",
          required: "N",
          json_name: "",
        },
        {
          field_no: "4",
          conv: "MCU",
          dict_name: "BILL.TO",
          desc_items: [
            {
              desc: "Bill.to",
            },
          ],
          just: "L",
          required: "N",
          json_name: "",
        },
        {
          field_no: "5",
          dict_name: "SHIP.ADDRESS",
          desc_items: [
            {
              desc: "Ship.address",
            },
          ],
          just: "L",
          required: "N",
          json_name: "",
        },
        {
          field_no: "6",
          dict_name: "CONTACT",
          desc_items: [
            {
              desc: "Contact",
            },
          ],
          just: "L",
          required: "N",
          json_name: "",
        },
        {
          field_no: "7",
          dict_name: "PHONE",
          desc_items: [
            {
              desc: "Phone",
            },
          ],
          just: "L",
          required: "N",
          json_name: "",
        },
        {
          field_no: "8",
          dict_name: "PO.NUMBER",
          desc_items: [
            {
              desc: "PO.Number",
            },
          ],
          just: "L",
          index: "Y",
          required: "N",
          json_name: "",
        },
        {
          field_no: "9",
          dict_name: "SHIP.VIA",
          desc_items: [
            {
              desc: "Ship.Via",
            },
          ],
          just: "L",
          index: "N",
          required: "N",
          json_name: "",
        },
        {
          field_no: "10",
          conv: "MCU",
          dict_name: "REP",
          desc_items: [
            {
              desc: "Rep",
            },
          ],
          just: "R",
          required: "Y",
          json_name: "",
        },
        {
          field_no: "11",
          conv: "MD2",
          dict_name: "ORDER.PCT",
          desc_items: [
            {
              desc: "Order.Pct",
            },
          ],
          just: "R",
          required: "N",
          json_name: "",
        },
        {
          field_no: "12",
          dict_name: "HOLD",
          desc_items: [
            {
              desc: "Hold",
            },
          ],
          just: "R",
          required: "N",
          json_name: "",
        },
        {
          field_no: "13",
          conv: "D2-",
          dict_name: "HOLD.DATE",
          desc_items: [
            {
              desc: "Hold",
            },
            {
              desc: "Date",
            },
          ],
          just: "R",
          required: "N",
          json_name: "",
        },
        {
          field_no: "14",
          dict_name: "STATUS",
          desc_items: [
            {
              desc: "Status",
            },
          ],
          just: "L",
          required: "N",
          json_name: "",
        },
        {
          field_no: "15",
          conv: "MCU",
          dict_name: "TERMS.CODE",
          desc_items: [
            {
              desc: "Terms",
            },
            {
              desc: "Code",
            },
          ],
          just: "L",
          index: "N",
          required: "N",
          json_name: "",
        },
        {
          field_no: "16",
          dict_name: "FOB",
          desc_items: [
            {
              desc: "FOB",
            },
          ],
          just: "L",
          required: "N",
          json_name: "",
        },
        {
          field_no: "17",
          dict_name: "CUST.CODE",
          desc_items: [
            {
              desc: "Cust",
            },
            {
              desc: "Code",
            },
          ],
          just: "L",
          required: "N",
          json_name: "",
        },
        {
          field_no: "18",
          conv: "MD4",
          dict_name: "TAX.RATE",
          desc_items: [
            {
              desc: "Tax.rate",
            },
          ],
          just: "R",
          index: "N",
          required: "N",
          json_name: "",
        },
        {
          field_no: "19",
          dict_name: "RESALE.NUMBER",
          desc_items: [
            {
              desc: "Resale",
            },
            {
              desc: "Number",
            },
          ],
          just: "L",
          index: "N",
          required: "N",
          json_name: "",
        },
        {
          field_no: "20",
          conv: "D2-",
          dict_name: "CLOSE.DATE",
          desc_items: [
            {
              desc: "Close",
            },
            {
              desc: "Date",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "21",
          dict_name: "LAST.SHIP.NO",
          desc_items: [
            {
              desc: "Last",
            },
            {
              desc: "Ship.No",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "22",
          conv: "D2-",
          dict_name: "LAST.DATE",
          desc_items: [
            {
              desc: "Last.date",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "23",
          dict_name: "NOTES",
          desc_items: [
            {
              desc: "Notes",
            },
          ],
          just: "T",
          index: "N",
          json_name: "",
        },
        {
          field_no: "24",
          conv: "D2-",
          dict_name: "STAMP.DATE",
          desc_items: [
            {
              desc: "Stamp.date",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "25",
          dict_name: "STAMP.ID",
          desc_items: [
            {
              desc: "Stamp.id",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "26",
          dict_name: "LIS",
          desc_items: [
            {
              desc: "Lis",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "27",
          conv: "MCU",
          dict_name: "LI.PARTS",
          desc_items: [
            {
              desc: "Li.Parts",
            },
          ],
          just: "L",
          index: "Y",
          json_name: "",
        },
        {
          field_no: "28",
          dict_name: "LI.DESCS",
          desc_items: [
            {
              desc: "Li.Descs",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "29",
          dict_name: "LI.REVS",
          desc_items: [
            {
              desc: "Li.Revs",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "30",
          conv: "D2-",
          dict_name: "LI.SCHED.DATES",
          desc_items: [
            {
              desc: "Li.Sched",
            },
            {
              desc: "Dates",
            },
          ],
          just: "R",
          index: "Y",
          json_name: "",
        },
        {
          field_no: "31",
          conv: "MD2",
          dict_name: "LI.SCHED.QTYS",
          desc_items: [
            {
              desc: "Li.Sched",
            },
            {
              desc: "Qtys",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "32",
          conv: "MD2",
          dict_name: "LI.ORDER.QTYS",
          desc_items: [
            {
              desc: "Li.Order",
            },
            {
              desc: "Qtys",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "33",
          conv: "MD2",
          dict_name: "LI.OPEN.QTYS",
          desc_items: [
            {
              desc: "Li.Open",
            },
            {
              desc: "Qtys",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "34",
          conv: "MD4",
          dict_name: "LI.PRICES",
          desc_items: [
            {
              desc: "Li.Prices",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "35",
          conv: "MD2",
          dict_name: "LI.DISCS",
          desc_items: [
            {
              desc: "Li.Discs",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "36",
          conv: "MD4",
          dict_name: "LI.DISC.AMTS",
          desc_items: [
            {
              desc: "Li.Disc",
            },
            {
              desc: "Amts",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "37",
          conv: "MCU",
          dict_name: "LI.FG.LOCS",
          desc_items: [
            {
              desc: "Li.Fg.locs",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "38",
          dict_name: "LI.NOTES",
          desc_items: [
            {
              desc: "Li.Notes",
            },
          ],
          just: "T",
          index: "N",
          json_name: "",
        },
        {
          field_no: "39",
          dict_name: "LI.TAXABLES",
          desc_items: [
            {
              desc: "Li.Taxables",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "40",
          conv: "MCU",
          dict_name: "LI.REPS",
          desc_items: [
            {
              desc: "Li.Reps",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "41",
          conv: "MD2",
          dict_name: "LI.COMMS",
          desc_items: [
            {
              desc: "Li.Comms",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "42",
          dict_name: "LI.HOLDS",
          desc_items: [
            {
              desc: "Li.Holds",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "43",
          conv: "D2-",
          dict_name: "LI.HOLD.DATES",
          desc_items: [
            {
              desc: "Li.Hold",
            },
            {
              desc: "Dates",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "44",
          dict_name: "LI.SHIP.NOS",
          desc_items: [
            {
              desc: "Li.Ship",
            },
            {
              desc: "Nos",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "45",
          conv: "D2-",
          dict_name: "LI.SHIP.DATES",
          desc_items: [
            {
              desc: "Li.Ship",
            },
            {
              desc: "Dates",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "46",
          conv: "MD2",
          dict_name: "LI.SHIP.QTYS",
          desc_items: [
            {
              desc: "Li.Ship",
            },
            {
              desc: "Qtys",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "47",
          conv: "MD2",
          dict_name: "LI.TOTAL.SHIP",
          desc_items: [
            {
              desc: "Li.Total",
            },
            {
              desc: "Ship",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "48",
          conv: "MCU",
          dict_name: "LI.SALES.ACCTS",
          desc_items: [
            {
              desc: "Li.Sales",
            },
            {
              desc: "Accts",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "49",
          dict_name: "LAST.RMA.NUMBER",
          desc_items: [
            {
              desc: "Last.Rma",
            },
            {
              desc: "Number",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "50",
          conv: "D2-",
          dict_name: "LAST.RMA.DATE",
          desc_items: [
            {
              desc: "Last.RMA",
            },
            {
              desc: "Date",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "51",
          dict_name: "TYPE",
          desc_items: [
            {
              desc: "Type",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "52",
          dict_name: "FREIGHT.CODE",
          desc_items: [
            {
              desc: "Freight",
            },
            {
              desc: "Code",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "53",
          conv: "MCU",
          dict_name: "LI.QUOTE.NO",
          desc_items: [
            {
              desc: "Li",
            },
            {
              desc: "Quote",
            },
          ],
          just: "R",
          required: "N",
          json_name: "",
        },
        {
          field_no: "54",
          dict_name: "COMMITED",
          desc_items: [
            {
              desc: "Commited",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "55",
          conv: "MCU",
          dict_name: "LI.WOS",
          desc_items: [
            {
              desc: "Li.Work",
            },
            {
              desc: "Orders",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "56",
          conv: "D2-",
          dict_name: "PRINT.DATE",
          desc_items: [
            {
              desc: "Print",
            },
            {
              desc: "Date",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "57",
          conv: "D2-",
          dict_name: "REQUIRED",
          desc_items: [
            {
              desc: "Required",
            },
            {
              desc: "Date",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "58",
          dict_name: "PARTIALS.OK",
          desc_items: [
            {
              desc: "Partials",
            },
            {
              desc: "Ok",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "59",
          conv: "MCU",
          dict_name: "TAX.CODES",
          desc_items: [
            {
              desc: "Tax",
            },
            {
              desc: "Codes",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "60",
          conv: "MCU",
          dict_name: "CG.LOC",
          desc_items: [
            {
              desc: "CG.Loc",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "61",
          conv: "MCU",
          dict_name: "QUOTE.NO",
          desc_items: [
            {
              desc: "Quote.No",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "62",
          dict_name: "LI.QUOTE.LI",
          desc_items: [
            {
              desc: "Li",
            },
            {
              desc: "Quote",
            },
            {
              desc: "Line",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "63",
          dict_name: "CUST.PART",
          desc_items: [
            {
              desc: "Cust.Part",
            },
          ],
          just: "L",
          index: "N",
          required: "N",
          json_name: "",
        },
        {
          field_no: "64",
          dict_name: "PLAN.GROUP",
          desc_items: [
            {
              desc: "Plan",
            },
            {
              desc: "Group",
            },
          ],
          just: "L",
          index: "N",
          required: "N",
          json_name: "",
        },
        {
          field_no: "65",
          dict_name: "EXT",
          desc_items: [
            {
              desc: "Ext",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "66",
          conv: "MCU",
          dict_name: "CO.CODE",
          desc_items: [
            {
              desc: "Co.Code",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "67",
          dict_name: "SHIP.CITY",
          desc_items: [
            {
              desc: "Ship.City",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "68",
          dict_name: "SHIP.STATE",
          desc_items: [
            {
              desc: "Ship.State",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "69",
          dict_name: "SHIP.ZIP",
          desc_items: [
            {
              desc: "Ship.Zip",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "70",
          dict_name: "SHIP.GEOCODE",
          desc_items: [
            {
              desc: "Ship.Geocode",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "71",
          conv: "MCU",
          dict_name: "PROJECT.ID",
          desc_items: [
            {
              desc: "Project.Id",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "72",
          dict_name: "TASK.ID",
          desc_items: [
            {
              desc: "Task.Id",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "73",
          dict_name: "SHIP.SEQ",
          desc_items: [
            {
              desc: "Ship.Seq",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "80",
          dict_name: "TEST.CUSTOM",
          desc_items: [
            {
              desc: "Test.Custom",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "101",
          conv: "MCU",
          dict_name: "VENDOR",
          desc_items: [
            {
              desc: "Vendor",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "102",
          conv: "MD4",
          dict_name: "PO.COST",
          desc_items: [
            {
              desc: "Po.Cost",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "103",
          conv: "MCU",
          dict_name: "PO.ID",
          desc_items: [
            {
              desc: "Po.Id",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "104",
          dict_name: "PO.LI",
          desc_items: [
            {
              desc: "Po.Li",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "105",
          dict_name: "DROP.SHIP",
          desc_items: [
            {
              desc: "Drop",
            },
            {
              desc: "Ship",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "106",
          dict_name: "SHIP.NAME",
          desc_items: [
            {
              desc: "Ship.Name",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "107",
          dict_name: "SHIP.COUNTRY",
          desc_items: [
            {
              desc: "Ship.Country",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "108",
          dict_name: "SHIP.ATTN",
          desc_items: [
            {
              desc: "Ship.Attn",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "109",
          dict_name: "SHIP.COMM",
          desc_items: [
            {
              desc: "Commercial?",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "110",
          dict_name: "SHIP.PHONE",
          desc_items: [
            {
              desc: "Ship.Phone",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "111",
          conv: "MD0",
          dict_name: "PRICE.PER",
          desc_items: [
            {
              desc: "Price",
            },
            {
              desc: "Per",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "112",
          dict_name: "PENDING.SHIP",
          desc_items: [
            {
              desc: "Pending",
            },
            {
              desc: "Ship?",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "113",
          dict_name: "CREDITCARD.NO",
          desc_items: [
            {
              desc: "Creditcard.No",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "114",
          dict_name: "CREDITCARD.TYPE",
          desc_items: [
            {
              desc: "Creditcard.Type",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "115",
          dict_name: "CARDHOLDER.NAME",
          desc_items: [
            {
              desc: "Cardholder.Name",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "116",
          dict_name: "CREDITCARD.EXP",
          desc_items: [
            {
              desc: "Creditcard.Exp",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "117",
          dict_name: "CREDITCARD.CVV",
          desc_items: [
            {
              desc: "Creditcard.Cvv",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "118",
          dict_name: "CREDITCARD.TRAN",
          desc_items: [
            {
              desc: "Creditcard.Tran",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "119",
          conv: "MD2",
          dict_name: "CREDITCARD.AMT",
          desc_items: [
            {
              desc: "Creditcard.Amt",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "120",
          dict_name: "CREDITCARD.POST",
          desc_items: [
            {
              desc: "Creditcard.Post",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "121",
          conv: "MD2",
          dict_name: "CC.CONV.FEE",
          desc_items: [
            {
              desc: "Cc.Conv.Fee",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "122",
          dict_name: "SHIP.EMAIL",
          desc_items: [
            {
              desc: "Ship.Email",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "123",
          dict_name: "SHIP.FRT.ACCT",
          desc_items: [
            {
              desc: "Third.Party",
            },
            {
              desc: "Account.Number",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "124",
          dict_name: "CONTACT.EMAIL",
          desc_items: [
            {
              desc: "Contact.Email",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "125",
          dict_name: "ORDER.SOURCE",
          desc_items: [
            {
              desc: "Order.Source",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "126",
          conv: "MD2",
          dict_name: "FREIGHT.AMOUNT",
          desc_items: [
            {
              desc: "Freight.Amount",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "127",
          dict_name: "FREIGHT.TYPE",
          desc_items: [
            {
              desc: "Freight.Type",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "128",
          dict_name: "FREIGHT.LOCK",
          desc_items: [
            {
              desc: "Freight.Lock",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "129",
          dict_name: "WRAP.DESC",
          desc_items: [
            {
              desc: "Wrap.Desc",
            },
          ],
          just: "T",
          index: "N",
          json_name: "",
        },
        {
          field_no: "130",
          conv: "MCU",
          dict_name: "COUPON.ID",
          desc_items: [
            {
              desc: "Coupon.Id",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "131",
          dict_name: "COUPON.TYPE",
          desc_items: [
            {
              desc: "Coupon.Type",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "132",
          conv: "MD2",
          dict_name: "COUP.DISC.AMT",
          desc_items: [
            {
              desc: "Coup.Disc.Amt",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "133",
          conv: "MD2",
          dict_name: "COUP.DISC.PCT",
          desc_items: [
            {
              desc: "Coup.Disc.Pct",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "134",
          conv: "MD2",
          dict_name: "COUP.MAX.DISC",
          desc_items: [
            {
              desc: "Coup.Max.Disc",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "135",
          conv: "MD2",
          dict_name: "COUP.MIN.ORD",
          desc_items: [
            {
              desc: "Coup.Min.Ord",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "136",
          dict_name: "COUP.LI",
          desc_items: [
            {
              desc: "Coup.Li",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "137",
          conv: "MD2",
          dict_name: "COUP.LI.DISC",
          desc_items: [
            {
              desc: "Coup.Li.Disc",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "138",
          conv: "MD2",
          dict_name: "COUP.SHIP.AMT",
          desc_items: [
            {
              desc: "Coup.Ship.Amt",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "139",
          dict_name: "EDI.ORDER",
          desc_items: [
            {
              desc: "Edi",
            },
            {
              desc: "Order",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "140",
          conv: "MCU",
          dict_name: "CONTACT.ID",
          desc_items: [
            {
              desc: "Contact.Id",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "141",
          conv: "MCU",
          dict_name: "CM.ID",
          desc_items: [
            {
              desc: "Credit.Memo",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "142",
          conv: "MD2",
          dict_name: "CM.AMOUNT",
          desc_items: [
            {
              desc: "CM.Amount",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "143",
          dict_name: "CC.ADDR",
          desc_items: [
            {
              desc: "Cc.Addr",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "144",
          dict_name: "CC.CITY",
          desc_items: [
            {
              desc: "Cc.City",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "145",
          dict_name: "CC.STATE",
          desc_items: [
            {
              desc: "Cc.State",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "146",
          dict_name: "CC.ZIP",
          desc_items: [
            {
              desc: "Cc.Zip",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "147",
          dict_name: "CC.COUNTRY",
          desc_items: [
            {
              desc: "Cc.Country",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "148",
          dict_name: "CREDITCARD.ERR",
          desc_items: [
            {
              desc: "Creditcard.Err",
            },
          ],
          just: "T",
          json_name: "",
        },
        {
          field_no: "151",
          dict_name: "CURRENCY.CODE",
          desc_items: [
            {
              desc: "Currency",
            },
            {
              desc: "Code",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "152",
          conv: "MD4",
          dict_name: "EXCHANGE.RATE",
          desc_items: [
            {
              desc: "Exchange",
            },
            {
              desc: "Rate",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "153",
          dict_name: "SAT.DELIVERY",
          desc_items: [
            {
              desc: "Saturday",
            },
            {
              desc: "Delivery",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "154",
          dict_name: "SHIPPER.ACCOUNT",
          desc_items: [
            {
              desc: "Shipper",
            },
            {
              desc: "Account",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "155",
          dict_name: "ACCT.RELEASE.ID",
          desc_items: [
            {
              desc: "Acct.Release.ID",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "156",
          dict_name: "END.USER.CNTRY",
          desc_items: [
            {
              desc: "End.User.Cntry",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "157",
          dict_name: "END.USER",
          desc_items: [
            {
              desc: "End.User",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "158",
          dict_name: "END.USER.STMT",
          desc_items: [
            {
              desc: "End.User.Stmt",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "159",
          dict_name: "LI.END.USE",
          desc_items: [
            {
              desc: "Li.End.Use",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "160",
          dict_name: "EXPORT.LIST",
          desc_items: [
            {
              desc: "Export.List",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "161",
          dict_name: "EXPORT.LIST.CHK",
          desc_items: [
            {
              desc: "Export.List.Chk",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "162",
          dict_name: "FIRST.SHIP.ONLY",
          desc_items: [
            {
              desc: "Freight First",
            },
            {
              desc: "Shipment Only",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "163",
          dict_name: "FRT.SERV.CODE",
          desc_items: [
            {
              desc: "Frt.Serv.Code",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "164",
          dict_name: "PKG.TYPE.CODE",
          desc_items: [
            {
              desc: "Pkg.Type.Code",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "165",
          dict_name: "PKG.WEIGHT",
          desc_items: [
            {
              desc: "Pkg.Weight",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "166",
          dict_name: "PKG.WEIGHT.UOM",
          desc_items: [
            {
              desc: "Pkg.Weight.Uom",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "167",
          dict_name: "PKG.LENGTH",
          desc_items: [
            {
              desc: "Pkg.Length",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "168",
          dict_name: "PKG.WIDTH",
          desc_items: [
            {
              desc: "Pkg.Width",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "169",
          dict_name: "PKG.HEIGHT",
          desc_items: [
            {
              desc: "Pkg.Height",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "170",
          dict_name: "PKG.DIM.UOM",
          desc_items: [
            {
              desc: "Pkg.Dim.Uom",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "171",
          conv: "MD2",
          dict_name: "PKG.VALUE",
          desc_items: [
            {
              desc: "Pkg.Value",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "172",
          conv: "MD2",
          dict_name: "FRT.MARKUP.AMT",
          desc_items: [
            {
              desc: "Frt.Markup.Amt",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "173",
          dict_name: "CREDIT.CARD.ID",
          desc_items: [
            {
              desc: "Credit.Card.Id",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "174",
          conv: "MD4",
          dict_name: "TAX.CODE.RATE",
          desc_items: [
            {
              desc: "Tax.Code.Rate",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "175",
          dict_name: "TAX.FREIGHT",
          desc_items: [
            {
              desc: "Tax.Freight",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "176",
          dict_name: "PAYMENT.PLAN",
          desc_items: [
            {
              desc: "Payment.Plan",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "177",
          dict_name: "PREPAY",
          desc_items: [
            {
              desc: "Prepay",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "178",
          conv: "MD2",
          dict_name: "TAX.AMOUNT",
          desc_items: [
            {
              desc: "Tax.Amount",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "179",
          dict_name: "MIVA.LI.NO",
          desc_items: [
            {
              desc: "Miva.Li.No",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "201",
          conv: "MCU",
          dict_name: "LOT.NUMBER",
          desc_items: [
            {
              desc: "Lot.Number",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "202",
          dict_name: "SIGNATURE",
          desc_items: [
            {
              desc: "Signature",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "204",
          conv: "D2-",
          dict_name: "SIGNATURE.DATE",
          desc_items: [
            {
              desc: "Signature.Date",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "205",
          conv: "MTS",
          dict_name: "SIGNATURE.TIME",
          desc_items: [
            {
              desc: "Signature.Time",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "206",
          dict_name: "SIGNATURE.BY",
          desc_items: [
            {
              desc: "Signature.By",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "207",
          dict_name: "SIGNATURE.TYPE",
          desc_items: [
            {
              desc: "Signature.Type",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "208",
          dict_name: "SHOPIFY.ID",
          desc_items: [
            {
              desc: "Shopify.Id",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "209",
          dict_name: "PRICE.MOD.CODE",
          desc_items: [
            {
              desc: "Price.Mod.Code",
            },
          ],
          just: "L",
          required: "N",
          json_name: "",
        },
        {
          field_no: "210",
          dict_name: "POS.ORDER.CODE",
          desc_items: [
            {
              desc: "Pos.Order.Code",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "211",
          conv: "MCU",
          dict_name: "SERIAL.ID",
          desc_items: [
            {
              desc: "Serial.Id",
            },
          ],
          just: "L",
          required: "N",
          json_name: "",
        },
        {
          field_no: "212",
          dict_name: "RELEASE.CODE",
          desc_items: [
            {
              desc: "Release.Code",
            },
          ],
          just: "L",
          required: "N",
          json_name: "",
        },
      ],
      correl_field_no_items: [],
    },
    {
      file_name: "CUST",
      field_no_items: [
        {
          field_no: "0",
          conv: "MCU",
          dict_name: "CUST.ID",
          desc_items: [
            {
              desc: "Cust",
            },
            {
              desc: "Id",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "1",
          dict_name: "NAME",
          desc_items: [
            {
              desc: "Name",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "2",
          dict_name: "ADDRESS",
          desc_items: [
            {
              desc: "Address",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "3",
          dict_name: "PHONE",
          desc_items: [
            {
              desc: "Phone",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "5",
          conv: "MCU",
          dict_name: "BILL.TO",
          desc_items: [
            {
              desc: "Bill.To",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "6",
          conv: "MD2",
          dict_name: "SALES.PERCENT",
          desc_items: [
            {
              desc: "Sales.Percent",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "7",
          dict_name: "SHIP.VIA",
          desc_items: [
            {
              desc: "Ship.via",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "8",
          conv: "MCU",
          dict_name: "REP",
          desc_items: [
            {
              desc: "Rep",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "9",
          conv: "MD2",
          dict_name: "ORDER.PCT",
          desc_items: [
            {
              desc: "Order",
            },
            {
              desc: "Pct",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "10",
          conv: "MCU",
          dict_name: "TERMS",
          desc_items: [
            {
              desc: "Terms",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "11",
          conv: "MD0",
          dict_name: "CREDIT.LIMIT",
          desc_items: [
            {
              desc: "Credit",
            },
            {
              desc: "Limit",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "13",
          dict_name: "RESALE",
          desc_items: [
            {
              desc: "Resale",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "14",
          conv: "MD2",
          dict_name: "DISC",
          desc_items: [
            {
              desc: "Disc",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "15",
          dict_name: "NOTES",
          desc_items: [
            {
              desc: "Notes",
            },
          ],
          just: "T",
          json_name: "",
        },
        {
          field_no: "16",
          conv: "MD2",
          dict_name: "AR.BAL",
          desc_items: [
            {
              desc: "AR.Bal",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "17",
          conv: "MD2",
          dict_name: "OPEN.ORDER.BAL",
          desc_items: [
            {
              desc: "Open.Order",
            },
            {
              desc: "Bal",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "18",
          conv: "D2-",
          dict_name: "STAMP.DATE",
          desc_items: [
            {
              desc: "Stamp",
            },
            {
              desc: "Date",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "19",
          dict_name: "STAMP.ID",
          desc_items: [
            {
              desc: "Stamp",
            },
            {
              desc: "ID",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "20",
          dict_name: "CODE",
          desc_items: [
            {
              desc: "Code",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "21",
          dict_name: "FAX",
          desc_items: [
            {
              desc: "FAX",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "22",
          dict_name: "CREDIT.HOLD",
          desc_items: [
            {
              desc: "Credit",
            },
            {
              desc: "Hold",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "24",
          dict_name: "SHIP.SEQ",
          desc_items: [
            {
              desc: "Ship",
            },
            {
              desc: "Seq",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "25",
          dict_name: "SHIP.TO",
          desc_items: [
            {
              desc: "Ship.To",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "26",
          conv: "MCU",
          dict_name: "TAX.CODES",
          desc_items: [
            {
              desc: "Tax",
            },
            {
              desc: "Codes",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "27",
          dict_name: "SORT.CITY",
          desc_items: [
            {
              desc: "Sort.City",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "28",
          dict_name: "SORT.STATE",
          desc_items: [
            {
              desc: "Sort",
            },
            {
              desc: "State",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "29",
          dict_name: "SORT.ZIP",
          desc_items: [
            {
              desc: "Sort.Zip",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "30",
          dict_name: "PARTIALS.OK",
          desc_items: [
            {
              desc: "Partials",
            },
            {
              desc: "Ok",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "31",
          dict_name: "SORT.COUNTRY",
          desc_items: [
            {
              desc: "Sort",
            },
            {
              desc: "Country",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "32",
          conv: "MCU",
          dict_name: "SHIP.TO.TAX",
          desc_items: [
            {
              desc: "Ship To",
            },
            {
              desc: "Tax Code",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "33",
          dict_name: "NAME.XREF",
          desc_items: [
            {
              desc: "Name.Xref",
            },
          ],
          just: "L",
          index: "Y",
          json_name: "",
        },
        {
          field_no: "34",
          conv: "MCU",
          dict_name: "CONTACT.ID",
          desc_items: [
            {
              desc: "Contact",
            },
            {
              desc: "ID",
            },
          ],
          just: "L",
          index: "Y",
          json_name: "",
        },
        {
          field_no: "35",
          dict_name: "CONTACT.TYPE",
          desc_items: [
            {
              desc: "Contact",
            },
            {
              desc: "Type",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "38",
          dict_name: "CAMPAIGN",
          desc_items: [
            {
              desc: "Campaign",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "39",
          dict_name: "MRK.OPT.OUT",
          desc_items: [
            {
              desc: "Mrk.Opt.Out",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "40",
          dict_name: "GROUP.CODE",
          desc_items: [
            {
              desc: "Group.Code",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "41",
          conv: "D2-",
          dict_name: "DATE",
          desc_items: [
            {
              desc: "Date",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "43",
          dict_name: "GEOCODE",
          desc_items: [
            {
              desc: "Geocode",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "44",
          dict_name: "SHIP.CITY",
          desc_items: [
            {
              desc: "Ship.City",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "45",
          dict_name: "SHIP.STATE",
          desc_items: [
            {
              desc: "Ship.State",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "46",
          dict_name: "SHIP.ZIP",
          desc_items: [
            {
              desc: "Ship.Zip",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "47",
          dict_name: "SHIP.GEOCODE",
          desc_items: [
            {
              desc: "Ship.Geocode",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "48",
          dict_name: "CONF.NOTES",
          desc_items: [
            {
              desc: "Confidential",
            },
            {
              desc: "Notes",
            },
          ],
          just: "T",
          json_name: "",
        },
        {
          field_no: "49",
          dict_name: "PHONE.XREF",
          desc_items: [
            {
              desc: "Phone.Xref",
            },
          ],
          just: "L",
          index: "Y",
          json_name: "",
        },
        {
          field_no: "50",
          dict_name: "PAST.DUE.DAYS",
          desc_items: [
            {
              desc: "Past",
            },
            {
              desc: "Due",
            },
            {
              desc: "Days",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "51",
          conv: "MCU",
          dict_name: "CONTRACT.ID",
          desc_items: [
            {
              desc: "Contract.Id",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "52",
          conv: "MCU",
          dict_name: "PROSPECT.ID",
          desc_items: [
            {
              desc: "Prospect",
            },
            {
              desc: "ID",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "53",
          conv: "MCU",
          dict_name: "ROUTE.ID",
          desc_items: [
            {
              desc: "Route.Id",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "54",
          dict_name: "STORE.ID",
          desc_items: [
            {
              desc: "Store.Id",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "55",
          dict_name: "SHIP.NAME",
          desc_items: [
            {
              desc: "Ship.Name",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "56",
          dict_name: "SHIP.COUNTRY",
          desc_items: [
            {
              desc: "Ship.Country",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "57",
          dict_name: "SHIP.ATTN",
          desc_items: [
            {
              desc: "Ship.Attn",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "58",
          dict_name: "SHIP.COMM",
          desc_items: [
            {
              desc: "Commercial?",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "59",
          dict_name: "SHIP.PHONE",
          desc_items: [
            {
              desc: "Ship.Phone",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "60",
          dict_name: "SHIP.FAX",
          desc_items: [
            {
              desc: "Ship.Fax",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "61",
          dict_name: "CREDIT.CARD.NO",
          desc_items: [
            {
              desc: "Credit.Card.No",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "62",
          dict_name: "CREDIT.CARD.EXP",
          desc_items: [
            {
              desc: "Credit.Card.Exp",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "63",
          dict_name: "CREDIT.CARD.SEC",
          desc_items: [
            {
              desc: "Credit.Card.Sec",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "64",
          dict_name: "CREDIT.CARD.ID",
          desc_items: [
            {
              desc: "Credit.Card.Id",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "72",
          dict_name: "BANK.NO",
          desc_items: [
            {
              desc: "Bank.No",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "73",
          dict_name: "CARDHOLDER.NAME",
          desc_items: [
            {
              desc: "Cardholder.Name",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "74",
          dict_name: "REBATE.TYPE",
          desc_items: [
            {
              desc: "Rebate.Type",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "75",
          conv: "MD2",
          dict_name: "REBATE.PCT",
          desc_items: [
            {
              desc: "Rebate",
            },
            {
              desc: "Pct",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "76",
          dict_name: "REBATE.FREQ",
          desc_items: [
            {
              desc: "Rebate.Freq",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "77",
          conv: "MCU",
          dict_name: "REBATE.ACCT",
          desc_items: [
            {
              desc: "Rebate.Acct",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "78",
          conv: "MD2",
          dict_name: "FROM.SALES.AMT",
          desc_items: [
            {
              desc: "From.Sales.Amt",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "79",
          conv: "MD2",
          dict_name: "TO.SALES.AMT",
          desc_items: [
            {
              desc: "To.Sales.Amt",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "101",
          dict_name: "SALES.FREQUENCY",
          desc_items: [
            {
              desc: "Sales",
            },
            {
              desc: "Frequency",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "102",
          conv: "MCU",
          dict_name: "SALES.ACCT",
          desc_items: [
            {
              desc: "Sales.Acct",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "103",
          dict_name: "SHIP.EMAIL",
          desc_items: [
            {
              desc: "Ship.Email",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "105",
          dict_name: "SHIP.ACCT.NO",
          desc_items: [
            {
              desc: "Ship.Acct.No",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "106",
          dict_name: "SHIP.CARRIER",
          desc_items: [
            {
              desc: "Ship.Carrier",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "107",
          dict_name: "CARRIER",
          desc_items: [
            {
              desc: "Carrier",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "108",
          dict_name: "FRT.ACCOUNT",
          desc_items: [
            {
              desc: "Frt.Account",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "109",
          dict_name: "THIRD.PARTY",
          desc_items: [
            {
              desc: "Third.Party",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "110",
          dict_name: "TP.STREET",
          desc_items: [
            {
              desc: "Third.Party",
            },
            {
              desc: "Street",
            },
          ],
          just: "T",
          index: "N",
          json_name: "",
        },
        {
          field_no: "111",
          dict_name: "TP.CITY",
          desc_items: [
            {
              desc: "Third.Party",
            },
            {
              desc: "City",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "112",
          dict_name: "TP.STATE",
          desc_items: [
            {
              desc: "Third.Party",
            },
            {
              desc: "State",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "113",
          dict_name: "TP.ZIP",
          desc_items: [
            {
              desc: "Third.Party",
            },
            {
              desc: "Zip",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "114",
          dict_name: "TP.COUNTRY",
          desc_items: [
            {
              desc: "Third.Party",
            },
            {
              desc: "Country",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "115",
          dict_name: "FREIGHT.CODE",
          desc_items: [
            {
              desc: "Freight",
            },
            {
              desc: "Code",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "116",
          dict_name: "WEB.CUST",
          desc_items: [
            {
              desc: "Web.Cust",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "117",
          dict_name: "QUICKLIST.NAME",
          desc_items: [
            {
              desc: "Quicklist.Name",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "118",
          conv: "MCU",
          dict_name: "QUICKLIST.PART",
          desc_items: [
            {
              desc: "Quicklist.Part",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "119",
          conv: "D2-",
          dict_name: "QUICKLIST.DATE",
          desc_items: [
            {
              desc: "Quicklist.Date",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "120",
          conv: "MD2",
          dict_name: "QUICKLIST.QTY",
          desc_items: [
            {
              desc: "Quicklist.Qty",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "121",
          conv: "MD4",
          dict_name: "QUICKLIST.PRICE",
          desc_items: [
            {
              desc: "Quicklist.Price",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "122",
          conv: "MD4",
          dict_name: "QUICKLIST.DISC",
          desc_items: [
            {
              desc: "Quicklist.Disc",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "123",
          conv: "D2-",
          dict_name: "INACTIVE.DATE",
          desc_items: [
            {
              desc: "Inactive",
            },
            {
              desc: "Date",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "130",
          dict_name: "CC.ADDR",
          desc_items: [
            {
              desc: "Cc.Addr",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "131",
          dict_name: "CC.CITY",
          desc_items: [
            {
              desc: "Cc.City",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "132",
          dict_name: "CC.STATE",
          desc_items: [
            {
              desc: "Cc.State",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "133",
          dict_name: "CC.ZIP",
          desc_items: [
            {
              desc: "Cc.Zip",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "134",
          dict_name: "CC.COUNTRY",
          desc_items: [
            {
              desc: "Cc.Country",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "137",
          dict_name: "EXPORTER",
          desc_items: [
            {
              desc: "Exporter",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "138",
          dict_name: "END.USER.CNTRY",
          desc_items: [
            {
              desc: "End.User",
            },
            {
              desc: "Countries",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "139",
          dict_name: "WEBSITE",
          desc_items: [
            {
              desc: "Website",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "140",
          dict_name: "ECOMMERCE.LEVEL",
          desc_items: [
            {
              desc: "Ecommerce.Level",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "141",
          dict_name: "CONTACT.PORTAL",
          desc_items: [
            {
              desc: "Contact.Portal",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "142",
          dict_name: "CURRENCY.CODE",
          desc_items: [
            {
              desc: "Currency.Code",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "143",
          dict_name: "USE.CURRENCY",
          desc_items: [
            {
              desc: "Use.Currency",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "144",
          conv: "D2-",
          dict_name: "AVATAX.DATE",
          desc_items: [
            {
              desc: "Avatax.Date",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "145",
          conv: "MTS",
          dict_name: "AVATAX.TIME",
          desc_items: [
            {
              desc: "Avatax.Time",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "146",
          dict_name: "BANK.ROUTING",
          desc_items: [
            {
              desc: "Bank.Routing",
            },
            {
              desc: "Number",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "147",
          dict_name: "BANK.ACCOUNT",
          desc_items: [
            {
              desc: "Bank.Account",
            },
            {
              desc: "Number",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "148",
          dict_name: "ACCOUNT.TYPE",
          desc_items: [
            {
              desc: "Bank",
            },
            {
              desc: "Account.Type",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "149",
          conv: "MD2",
          dict_name: "RATE",
          desc_items: [
            {
              desc: "Rate",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "150",
          dict_name: "TYPE",
          desc_items: [
            {
              desc: "Type",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "151",
          dict_name: "BILL.RATE.CODE",
          desc_items: [
            {
              desc: "Bill.Rate.Code",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "152",
          dict_name: "BRANCH.CODE",
          desc_items: [
            {
              desc: "Branch.Code",
            },
          ],
          just: "L",
          index: "Y",
          required: "N",
          json_name: "",
        },
        {
          field_no: "153",
          dict_name: "EXCL.SPECIALS",
          desc_items: [
            {
              desc: "Excl.Specials",
            },
          ],
          just: "L",
          index: "N",
          required: "N",
          json_name: "",
        },
        {
          field_no: "154",
          dict_name: "SHIP.PRICE.CODE",
          desc_items: [
            {
              desc: "Ship.Price.Code",
            },
          ],
          just: "L",
          required: "N",
          json_name: "",
        },
        {
          field_no: "155",
          dict_name: "SHIP.EXCL.SPEC",
          desc_items: [
            {
              desc: "Ship.Excl.Spec",
            },
          ],
          just: "L",
          index: "N",
          required: "N",
          json_name: "",
        },
      ],
      correl_field_no_items: [],
    },
    {
      file_name: "PARTS",
      field_no_items: [
        {
          field_no: "0",
          conv: "MCU",
          dict_name: "PART.NO",
          desc_items: [
            {
              desc: "Part.No",
            },
          ],
          just: "L",
          index: "Y",
          json_name: "",
        },
        {
          field_no: "1",
          dict_name: "DESCRIPTION",
          desc_items: [
            {
              desc: "Description",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "2",
          dict_name: "TYPE",
          desc_items: [
            {
              desc: "Type",
            },
          ],
          just: "L",
          required: "Y",
          json_name: "",
        },
        {
          field_no: "3",
          dict_name: "UM",
          desc_items: [
            {
              desc: "UM",
            },
          ],
          just: "L",
          required: "Y",
          json_name: "",
        },
        {
          field_no: "4",
          dict_name: "SPECS",
          desc_items: [
            {
              desc: "Specs",
            },
          ],
          just: "L",
          index: "Y",
          json_name: "",
        },
        {
          field_no: "5",
          dict_name: "NOTES",
          desc_items: [
            {
              desc: "Notes",
            },
          ],
          just: "T",
          json_name: "",
        },
        {
          field_no: "6",
          dict_name: "MFG.NAME",
          desc_items: [
            {
              desc: "Mfg.Name",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "7",
          dict_name: "MFG.PART",
          desc_items: [
            {
              desc: "Manufacturer",
            },
            {
              desc: "Part.Number",
            },
          ],
          just: "L",
          index: "Y",
          required: "N",
          json_name: "",
        },
        {
          field_no: "8",
          dict_name: "REV",
          desc_items: [
            {
              desc: "Rev",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "9",
          dict_name: "ABC.CODE",
          desc_items: [
            {
              desc: "ABC",
            },
            {
              desc: "Code",
            },
          ],
          just: "L",
          index: "N",
          required: "N",
          json_name: "",
        },
        {
          field_no: "10",
          dict_name: "SAFETY.STOCK",
          desc_items: [
            {
              desc: "Safety",
            },
            {
              desc: "Stock",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "11",
          dict_name: "ORDER.QTY",
          desc_items: [
            {
              desc: "Order",
            },
            {
              desc: "Qty",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "12",
          dict_name: "LEAD.TIME",
          desc_items: [
            {
              desc: "Lead",
            },
            {
              desc: "Time",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "13",
          conv: "MCU",
          dict_name: "PRODCON.ID",
          desc_items: [
            {
              desc: "PRODCON.ID",
            },
          ],
          just: "L",
          index: "Y",
          json_name: "",
        },
        {
          field_no: "14",
          dict_name: "FRACTIONS",
          desc_items: [
            {
              desc: "Fractions",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "15",
          dict_name: "STATUS",
          desc_items: [
            {
              desc: "Status",
            },
          ],
          just: "L",
          required: "Y",
          json_name: "",
        },
        {
          field_no: "16",
          dict_name: "MRP.PART",
          desc_items: [
            {
              desc: "MRP",
            },
            {
              desc: "Part",
            },
          ],
          just: "L",
          index: "Y",
          json_name: "",
        },
        {
          field_no: "17",
          dict_name: "MIN.MAX.PART",
          desc_items: [
            {
              desc: "Min/",
            },
            {
              desc: "Max",
            },
            {
              desc: "Part",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "18",
          dict_name: "PICKLIST.LABELS",
          desc_items: [
            {
              desc: "Picklist",
            },
            {
              desc: "Labels",
            },
          ],
          just: "L",
          required: "N",
          json_name: "",
        },
        {
          field_no: "19",
          dict_name: "MAX.STOCK",
          desc_items: [
            {
              desc: "Max",
            },
            {
              desc: "Stock",
            },
          ],
          just: "R",
          required: "N",
          json_name: "",
        },
        {
          field_no: "20",
          conv: "MCU",
          dict_name: "VENDOR",
          desc_items: [
            {
              desc: "Vendor",
            },
          ],
          just: "L",
          required: "N",
          json_name: "",
        },
        {
          field_no: "21",
          dict_name: "LOT.CONTROL",
          desc_items: [
            {
              desc: "Lot",
            },
            {
              desc: "Control",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "22",
          conv: "MD0",
          dict_name: "SHELF.LIFE",
          desc_items: [
            {
              desc: "Shelf",
            },
            {
              desc: "Life",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "23",
          dict_name: "BUYER",
          desc_items: [
            {
              desc: "Buyer",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "24",
          dict_name: "PLANNER",
          desc_items: [
            {
              desc: "Planner",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "25",
          dict_name: "REV.HISTORY",
          desc_items: [
            {
              desc: "Rev",
            },
            {
              desc: "History",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "26",
          dict_name: "REV.DESC",
          desc_items: [
            {
              desc: "Rev.Desc",
            },
          ],
          just: "L",
          required: "N",
          json_name: "",
        },
        {
          field_no: "27",
          conv: "MCU",
          dict_name: "REV.ECN",
          desc_items: [
            {
              desc: "Rev.ECN",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "28",
          conv: "D2-",
          dict_name: "REV.DATE",
          desc_items: [
            {
              desc: "Rev.Date",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "29",
          dict_name: "DRAWING.NUMBER",
          desc_items: [
            {
              desc: "Drawing",
            },
            {
              desc: "Number",
            },
          ],
          just: "L",
          index: "Y",
          json_name: "",
        },
        {
          field_no: "30",
          dict_name: "BUY.UM",
          desc_items: [
            {
              desc: "Buy",
            },
            {
              desc: "U/M",
            },
          ],
          just: "L",
          required: "N",
          json_name: "",
        },
        {
          field_no: "31",
          dict_name: "FACTOR",
          desc_items: [
            {
              desc: "Factor",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "32",
          conv: "MD2",
          dict_name: "WEIGHT",
          desc_items: [
            {
              desc: "Weight",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "33",
          conv: "MCU",
          dict_name: "INVLOC",
          desc_items: [
            {
              desc: "Invloc",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "34",
          dict_name: "CONFIG.GROUP",
          desc_items: [
            {
              desc: "Config.Group",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "35",
          dict_name: "CONFIG.OPTION",
          desc_items: [
            {
              desc: "Config",
            },
            {
              desc: "Option",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "36",
          dict_name: "CONFIG.NOTES",
          desc_items: [
            {
              desc: "Config.Notes",
            },
          ],
          just: "T",
          json_name: "",
        },
        {
          field_no: "37",
          dict_name: "CONFIG.DESC",
          desc_items: [
            {
              desc: "Config.Desc",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "38",
          dict_name: "CONFIG.ID",
          desc_items: [
            {
              desc: "Config.ID",
            },
          ],
          just: "L",
          index: "Y",
          json_name: "",
        },
        {
          field_no: "39",
          conv: "MCU",
          dict_name: "MRK.CODE",
          desc_items: [
            {
              desc: "MRK.Code",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "41",
          dict_name: "MODEL",
          desc_items: [
            {
              desc: "Model",
            },
          ],
          just: "L",
          index: "Y",
          json_name: "",
        },
        {
          field_no: "42",
          dict_name: "CATEGORY",
          desc_items: [
            {
              desc: "Category",
            },
          ],
          just: "L",
          index: "Y",
          json_name: "",
        },
        {
          field_no: "43",
          dict_name: "LOW.LEVEL.CODE",
          desc_items: [
            {
              desc: "Low",
            },
            {
              desc: "Level",
            },
            {
              desc: "Code",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "44",
          dict_name: "TPOP",
          desc_items: [
            {
              desc: "TPOP",
            },
          ],
          just: "R",
          index: "N",
          required: "N",
          json_name: "",
        },
        {
          field_no: "45",
          conv: "MCU",
          dict_name: "ROUTING",
          desc_items: [
            {
              desc: "Routing",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "46",
          dict_name: "PEGGING",
          desc_items: [
            {
              desc: "Pegging",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "47",
          dict_name: "ORDER.POLICY",
          desc_items: [
            {
              desc: "Order",
            },
            {
              desc: "Policy",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "48",
          dict_name: "ORDER.MULTIPLE",
          desc_items: [
            {
              desc: "Order",
            },
            {
              desc: "Multiple",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "49",
          dict_name: "ORDER.MAXIMUM",
          desc_items: [
            {
              desc: "Order",
            },
            {
              desc: "Maximum",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "50",
          conv: "MD2",
          dict_name: "SCRAP.FACTOR",
          desc_items: [
            {
              desc: "Scrap",
            },
            {
              desc: "Factor",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "52",
          dict_name: "MAKE.BUY",
          desc_items: [
            {
              desc: "Make",
            },
            {
              desc: "/Buy",
            },
          ],
          just: "L",
          required: "N",
          json_name: "",
        },
        {
          field_no: "53",
          dict_name: "MPS.PART",
          desc_items: [
            {
              desc: "MPS",
            },
            {
              desc: "Part",
            },
          ],
          just: "L",
          required: "N",
          json_name: "",
        },
        {
          field_no: "54",
          dict_name: "MPS.LOW.LEVEL",
          desc_items: [
            {
              desc: "MPS",
            },
            {
              desc: "Low",
            },
            {
              desc: "Level",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "55",
          dict_name: "PLAN.GROUP",
          desc_items: [
            {
              desc: "Plan",
            },
            {
              desc: "Group",
            },
          ],
          just: "l",
          required: "N",
          json_name: "",
        },
        {
          field_no: "56",
          conv: "MCU",
          dict_name: "WIP.LOC",
          desc_items: [
            {
              desc: "WIP.Loc",
            },
          ],
          just: "L",
          index: "N",
          required: "N",
          json_name: "",
        },
        {
          field_no: "57",
          conv: "MCU",
          dict_name: "RI.LOC",
          desc_items: [
            {
              desc: "RI.Loc",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "58",
          conv: "D2-",
          dict_name: "STAMP.DATE",
          desc_items: [
            {
              desc: "Stamp",
            },
            {
              desc: "Date",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "59",
          dict_name: "STAMP.USER",
          desc_items: [
            {
              desc: "Stamp",
            },
            {
              desc: "User",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "60",
          dict_name: "TAXABLE",
          desc_items: [
            {
              desc: "Taxable",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "61",
          dict_name: "DESC.WORDS",
          desc_items: [
            {
              desc: "Desc",
            },
            {
              desc: "Words",
            },
          ],
          just: "L",
          index: "Y",
          json_name: "",
        },
        {
          field_no: "62",
          conv: "MCU",
          dict_name: "STOCK.LOC",
          desc_items: [
            {
              desc: "Stock",
            },
            {
              desc: "Loc",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "63",
          conv: "MCU",
          dict_name: "LSTOCK.LOC",
          desc_items: [
            {
              desc: "Lstock",
            },
            {
              desc: "Loc",
            },
          ],
          just: "L",
          index: "N",
          required: "N",
          json_name: "",
        },
        {
          field_no: "64",
          dict_name: "UPC",
          desc_items: [
            {
              desc: "Upc",
            },
          ],
          just: "L",
          index: "Y",
          required: "N",
          json_name: "",
        },
        {
          field_no: "66",
          dict_name: "CREATE.WO",
          desc_items: [
            {
              desc: "Create",
            },
            {
              desc: "WO",
            },
          ],
          just: "L",
          required: "N",
          json_name: "",
        },
        {
          field_no: "67",
          dict_name: "IMAGE.DESC",
          desc_items: [
            {
              desc: "Image.Desc",
            },
          ],
          just: "L",
          required: "N",
          json_name: "",
        },
        {
          field_no: "68",
          dict_name: "IMAGE.PATH",
          desc_items: [
            {
              desc: "Image.Path",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "69",
          conv: "MD4",
          dict_name: "FET",
          desc_items: [
            {
              desc: "Fet",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "70",
          dict_name: "WEB.CATEGORY",
          desc_items: [
            {
              desc: "Web.Category",
            },
          ],
          just: "L",
          index: "Y",
          json_name: "",
        },
        {
          field_no: "71",
          dict_name: "WEB.DESCRIPTION",
          desc_items: [
            {
              desc: "Web.Description",
            },
          ],
          just: "T",
          json_name: "",
        },
        {
          field_no: "72",
          dict_name: "WEB.INFORMATION",
          desc_items: [
            {
              desc: "Web.Information",
            },
          ],
          just: "T",
          json_name: "",
        },
        {
          field_no: "73",
          dict_name: "WEB.IMAGE.PATH",
          desc_items: [
            {
              desc: "Web.Image.Path",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "74",
          conv: "MCU",
          dict_name: "CUST",
          desc_items: [
            {
              desc: "Cust",
            },
          ],
          just: "L",
          required: "N",
          json_name: "",
        },
        {
          field_no: "75",
          dict_name: "CUST.PART",
          desc_items: [
            {
              desc: "Cust.Part",
            },
          ],
          just: "L",
          index: "Y",
          json_name: "",
        },
        {
          field_no: "76",
          conv: "MCU",
          dict_name: "EQUIV.PART",
          desc_items: [
            {
              desc: "Equiv.Part",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "77",
          dict_name: "SUPERCEDE.FLAG",
          desc_items: [
            {
              desc: "Supercede",
            },
            {
              desc: "Flag",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "78",
          dict_name: "SALES.HOLD",
          desc_items: [
            {
              desc: "Sales",
            },
            {
              desc: "Hold",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "79",
          dict_name: "SERIAL.REQUIRED",
          desc_items: [
            {
              desc: "Serial",
            },
            {
              desc: "Rqd",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "101",
          dict_name: "BOM.UM",
          desc_items: [
            {
              desc: "Bom",
            },
            {
              desc: "U/M",
            },
          ],
          just: "L",
          required: "N",
          json_name: "",
        },
        {
          field_no: "102",
          dict_name: "BOM.UM.FACTOR",
          desc_items: [
            {
              desc: "Bom.UM",
            },
            {
              desc: "Factor",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "103",
          dict_name: "NO.CAT.DISC",
          desc_items: [
            {
              desc: "No.Cat.Disc",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "104",
          dict_name: "MRP.DECIMALS",
          desc_items: [
            {
              desc: "MRP",
            },
            {
              desc: "Decimals",
            },
          ],
          just: "R",
          required: "N",
          json_name: "",
        },
        {
          field_no: "105",
          dict_name: "COUNTRY.ORIGIN",
          desc_items: [
            {
              desc: "Country",
            },
            {
              desc: "of Origin",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "106",
          dict_name: "MAKE.HOLD",
          desc_items: [
            {
              desc: "Make.Hold",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "107",
          dict_name: "BUY.HOLD",
          desc_items: [
            {
              desc: "Buy.Hold",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "108",
          dict_name: "WRAP.DESC",
          desc_items: [
            {
              desc: "Wrap.Desc",
            },
          ],
          just: "T",
          index: "N",
          required: "N",
          json_name: "",
        },
        {
          field_no: "109",
          conv: "MCU",
          dict_name: "WEB.CUSTOMER",
          desc_items: [
            {
              desc: "Web.Customer",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "110",
          dict_name: "WEB.FEATURED",
          desc_items: [
            {
              desc: "Web.Featured",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "111",
          dict_name: "ROHS",
          desc_items: [
            {
              desc: "Rohs",
            },
          ],
          just: "L",
          required: "N",
          json_name: "",
        },
        {
          field_no: "112",
          conv: "D2-",
          dict_name: "DATE.CREATED",
          desc_items: [
            {
              desc: "Date",
            },
            {
              desc: "Created",
            },
          ],
          just: "R",
          index: "N",
          required: "N",
          json_name: "",
        },
        {
          field_no: "113",
          dict_name: "WEB.NO.BUY",
          desc_items: [
            {
              desc: "Web.No.Buy",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "114",
          dict_name: "WEB.PRICE.TEXT",
          desc_items: [
            {
              desc: "Web.Price.Text",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "121",
          dict_name: "EXP.REGULATION",
          desc_items: [
            {
              desc: "Export",
            },
            {
              desc: "Regulation",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "122",
          dict_name: "BIS.LICENSE",
          desc_items: [
            {
              desc: "Bis.License",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "123",
          dict_name: "HTS.SCHEDB",
          desc_items: [
            {
              desc: "Hts.Schedb",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "124",
          conv: "MD2",
          dict_name: "SHIP.LENGTH",
          desc_items: [
            {
              desc: "Ship.Length",
            },
          ],
          just: "R",
          required: "N",
          json_name: "",
        },
        {
          field_no: "125",
          conv: "MD2",
          dict_name: "SHIP.WIDTH",
          desc_items: [
            {
              desc: "Ship.Width",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "126",
          conv: "MD2",
          dict_name: "SHIP.HEIGHT",
          desc_items: [
            {
              desc: "Ship.Height",
            },
          ],
          just: "R",
          required: "N",
          json_name: "",
        },
        {
          field_no: "127",
          dict_name: "SHIP.SEPARATE",
          desc_items: [
            {
              desc: "Ship.Separate",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "128",
          dict_name: "INSP.STEP",
          desc_items: [
            {
              desc: "Insp.Step",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "129",
          dict_name: "INSP.INSTRUCT",
          desc_items: [
            {
              desc: "Insp.Instruct",
            },
          ],
          just: "T",
          index: "N",
          json_name: "",
        },
        {
          field_no: "130",
          conv: "MD2",
          dict_name: "SAMPLE.PCT",
          desc_items: [
            {
              desc: "Sample.Pct",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "133",
          conv: "MD2",
          dict_name: "SHIP.QTY",
          desc_items: [
            {
              desc: "Ship.Qty",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "134",
          dict_name: "CONFIG.CODE",
          desc_items: [
            {
              desc: "Config.Code",
            },
          ],
          just: "L",
          index: "Y",
          json_name: "",
        },
        {
          field_no: "135",
          dict_name: "AVATAX.CODE",
          desc_items: [
            {
              desc: "Avatax.Code",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "136",
          dict_name: "MIVA.PRODUCT.ID",
          desc_items: [
            {
              desc: "Miva.Product.Id",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "137",
          dict_name: "MIVA.IMAGES",
          desc_items: [
            {
              desc: "Miva.Images",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "138",
          dict_name: "IMAGE.ID",
          desc_items: [
            {
              desc: "Image.Id",
            },
          ],
          just: "R",
          required: "N",
          json_name: "",
        },
        {
          field_no: "139",
          dict_name: "IMAGE.DEFAULT",
          desc_items: [
            {
              desc: "Image.Default",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "140",
          conv: "MCU",
          dict_name: "ALT.PART.NO",
          desc_items: [
            {
              desc: "Alt.Part.No",
            },
          ],
          just: "L",
          index: "Y",
          required: "N",
          json_name: "",
        },
        {
          field_no: "141",
          dict_name: "BOM.DECIMALS",
          desc_items: [
            {
              desc: "Bom.Decimals",
            },
          ],
          just: "L",
          required: "N",
          json_name: "",
        },
        {
          field_no: "142",
          conv: "MD0",
          dict_name: "TPOP.DAYS",
          desc_items: [
            {
              desc: "Tpop.Days",
            },
          ],
          just: "R",
          required: "N",
          json_name: "",
        },
        {
          field_no: "143",
          conv: "MD4",
          dict_name: "PRODUCT.WEIGHT",
          desc_items: [
            {
              desc: "Product.Weight",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "144",
          conv: "MCU",
          dict_name: "ALT.ROUTING",
          desc_items: [
            {
              desc: "Alt.Routing",
            },
          ],
          just: "L",
          required: "N",
          json_name: "",
        },
        {
          field_no: "145",
          dict_name: "NO.NET.PRICE",
          desc_items: [
            {
              desc: "No.Net.Price",
            },
          ],
          just: "L",
          required: "N",
          json_name: "",
        },
        {
          field_no: "146",
          conv: "MCU",
          dict_name: "RIDEF.ID",
          desc_items: [
            {
              desc: "Ridef.Id",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "147",
          conv: "MCU",
          dict_name: "AUTO.RI",
          desc_items: [
            {
              desc: "Auto.Ri",
            },
          ],
          just: "L",
          required: "N",
          json_name: "",
        },
        {
          field_no: "148",
          dict_name: "PROD.GROUP",
          desc_items: [
            {
              desc: "Prod.Group",
            },
          ],
          just: "L",
          index: "N",
          required: "N",
          json_name: "",
        },
        {
          field_no: "149",
          conv: "MD2",
          dict_name: "BOM.SCRAP.PCT",
          desc_items: [
            {
              desc: "Bom.Scrap.Pct",
            },
          ],
          just: "R",
          index: "N",
          required: "N",
          json_name: "",
        },
        {
          field_no: "150",
          conv: "MD4",
          dict_name: "BASIS1.PRICE",
          desc_items: [
            {
              desc: "Basis1.Price",
            },
          ],
          just: "R",
          index: "N",
          required: "N",
          json_name: "",
        },
        {
          field_no: "151",
          conv: "MD4",
          dict_name: "BASIS2.PRICE",
          desc_items: [
            {
              desc: "Basis2.Price",
            },
          ],
          just: "R",
          index: "N",
          required: "N",
          json_name: "",
        },
        {
          field_no: "152",
          conv: "MD4",
          dict_name: "BASIS3.PRICE",
          desc_items: [
            {
              desc: "Basis3.Price",
            },
          ],
          just: "R",
          index: "N",
          required: "N",
          json_name: "",
        },
        {
          field_no: "153",
          conv: "MD4",
          dict_name: "BASIS4.PRICE",
          desc_items: [
            {
              desc: "Basis4.Price",
            },
          ],
          just: "R",
          index: "N",
          required: "N",
          json_name: "",
        },
        {
          field_no: "154",
          conv: "MD4",
          dict_name: "BASIS5.PRICE",
          desc_items: [
            {
              desc: "Basis5.Price",
            },
          ],
          just: "R",
          index: "N",
          required: "N",
          json_name: "",
        },
        {
          field_no: "155",
          conv: "MD4",
          dict_name: "BASIS6.PRICE",
          desc_items: [
            {
              desc: "Basis6.Price",
            },
          ],
          just: "R",
          required: "N",
          json_name: "",
        },
      ],
      correl_field_no_items: [],
    },
    {
      file_name: "AR",
      field_no_items: [
        {
          field_no: "0",
          conv: "MCU",
          dict_name: "AR.ID",
          desc_items: [
            {
              desc: "AR.Id",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "1",
          conv: "MCU",
          dict_name: "CUST",
          desc_items: [
            {
              desc: "Cust",
            },
          ],
          just: "L",
          index: "Y",
          json_name: "",
        },
        {
          field_no: "2",
          dict_name: "PO",
          desc_items: [
            {
              desc: "PO",
            },
          ],
          just: "R",
          index: "Y",
          json_name: "",
        },
        {
          field_no: "3",
          dict_name: "SOURCE.DOC",
          desc_items: [
            {
              desc: "Source.Doc",
            },
          ],
          just: "R",
          index: "Y",
          json_name: "",
        },
        {
          field_no: "4",
          conv: "MCU",
          dict_name: "AR.ACCOUNT",
          desc_items: [
            {
              desc: "AR.Account",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "5",
          conv: "D2-",
          dict_name: "INVOICE.DATE",
          desc_items: [
            {
              desc: "Invoice",
            },
            {
              desc: "Date",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "6",
          conv: "MD2",
          dict_name: "INVOICE.AMT",
          desc_items: [
            {
              desc: "Invoice.Amt",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "7",
          dict_name: "CHECKS",
          desc_items: [
            {
              desc: "Checks",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "8",
          conv: "D2-",
          dict_name: "CHECK.DATES",
          desc_items: [
            {
              desc: "Check",
            },
            {
              desc: "Dates",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "9",
          conv: "MD2",
          dict_name: "CHECK.AMOUNTS",
          desc_items: [
            {
              desc: "Check.Amounts",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "10",
          conv: "MCU",
          dict_name: "CASH.ID",
          desc_items: [
            {
              desc: "Cash.ID",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "11",
          conv: "MD2",
          dict_name: "MISC.AMOUNT",
          desc_items: [
            {
              desc: "Misc.Amount",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "12",
          dict_name: "MISC.DESC",
          desc_items: [
            {
              desc: "Misc.Desc",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "13",
          conv: "MCU",
          dict_name: "MISC.ACCOUNT",
          desc_items: [
            {
              desc: "Misc.Account",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "14",
          conv: "MD2",
          dict_name: "FREIGHT.AMT",
          desc_items: [
            {
              desc: "Freight.Amt",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "15",
          conv: "MCU",
          dict_name: "FREIGHT.ACCT",
          desc_items: [
            {
              desc: "Freight.Acct",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "16",
          conv: "MCU",
          dict_name: "TERMS.CODE",
          desc_items: [
            {
              desc: "Terms.Code",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "17",
          conv: "MD2",
          dict_name: "DISC.PCT",
          desc_items: [
            {
              desc: "Disc.Pct",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "18",
          conv: "MD2",
          dict_name: "DISC.AMT",
          desc_items: [
            {
              desc: "Disc.Amount",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "19",
          conv: "MCU",
          dict_name: "DISC.ACCT",
          desc_items: [
            {
              desc: "Disc.Acct",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "20",
          conv: "D2-",
          dict_name: "DUE.DATE",
          desc_items: [
            {
              desc: "Due.Date",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "21",
          conv: "MCU",
          dict_name: "REP",
          desc_items: [
            {
              desc: "Rep",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "22",
          conv: "MD2",
          dict_name: "REP.ORD.PCT",
          desc_items: [
            {
              desc: "Rep.Ord",
            },
            {
              desc: "Pct",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "23",
          dict_name: "STATUS",
          desc_items: [
            {
              desc: "Status",
            },
          ],
          just: "L",
          index: "Y",
          json_name: "",
        },
        {
          field_no: "24",
          dict_name: "TYPE",
          desc_items: [
            {
              desc: "Type",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "25",
          dict_name: "NOTES",
          desc_items: [
            {
              desc: "Notes",
            },
          ],
          just: "T",
          json_name: "",
        },
        {
          field_no: "27",
          dict_name: "HOLD.FLAG",
          desc_items: [
            {
              desc: "Hold",
            },
            {
              desc: "Flag",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "28",
          conv: "D2-",
          dict_name: "HOLD.DATE",
          desc_items: [
            {
              desc: "Hold.Date",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "29",
          conv: "D2-",
          dict_name: "STAMP.DATE",
          desc_items: [
            {
              desc: "Stamp",
            },
            {
              desc: "Date",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "30",
          dict_name: "STAMP.ID",
          desc_items: [
            {
              desc: "Stamp",
            },
            {
              desc: "ID",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "31",
          dict_name: "LI",
          desc_items: [
            {
              desc: "Li#",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "32",
          conv: "MCU",
          dict_name: "PART",
          desc_items: [
            {
              desc: "Part.Number",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "33",
          dict_name: "DESCRIPTION",
          desc_items: [
            {
              desc: "Description",
            },
          ],
          just: "T",
          json_name: "",
        },
        {
          field_no: "34",
          conv: "MD2",
          dict_name: "QTY",
          desc_items: [
            {
              desc: "Qty",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "35",
          conv: "MD4",
          dict_name: "PRICE",
          desc_items: [
            {
              desc: "Price",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "36",
          conv: "MD2",
          dict_name: "LI.DISC.PCT",
          desc_items: [
            {
              desc: "Li.Disc",
            },
            {
              desc: "Percent",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "37",
          conv: "MD4",
          dict_name: "LI.DISC.AMT",
          desc_items: [
            {
              desc: "Li.Disc",
            },
            {
              desc: "Amount",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "38",
          dict_name: "TAX.FLAG",
          desc_items: [
            {
              desc: "Tax",
            },
            {
              desc: "Flag",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "40",
          conv: "MD2",
          dict_name: "AMOUNT",
          desc_items: [
            {
              desc: "Amount",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "41",
          conv: "MCU",
          dict_name: "SALES.ACCT",
          desc_items: [
            {
              desc: "Sales",
            },
            {
              desc: "Account",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "42",
          dict_name: "LI.NOTES",
          desc_items: [
            {
              desc: "Line.Notes",
            },
          ],
          just: "T",
          index: "N",
          json_name: "",
        },
        {
          field_no: "44",
          dict_name: "MISC.TAXABLE",
          desc_items: [
            {
              desc: "Misc",
            },
            {
              desc: "Taxable",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "45",
          conv: "MCU",
          dict_name: "TAX.CODE",
          desc_items: [
            {
              desc: "Tax",
            },
            {
              desc: "Code",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "46",
          conv: "MD4",
          dict_name: "TAX.PCT",
          desc_items: [
            {
              desc: "Tax",
            },
            {
              desc: "Pct",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "47",
          conv: "MD2",
          dict_name: "TAX.AMT",
          desc_items: [
            {
              desc: "Tax.Amt",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "48",
          conv: "MCU",
          dict_name: "LI.REP",
          desc_items: [
            {
              desc: "Li.Rep",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "49",
          conv: "MD2",
          dict_name: "LI.COMM.PCT",
          desc_items: [
            {
              desc: "Li",
            },
            {
              desc: "Comm%",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "50",
          conv: "MD2",
          dict_name: "BALANCE",
          desc_items: [
            {
              desc: "Balance",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "51",
          dict_name: "CUST.CODE",
          desc_items: [
            {
              desc: "Cust",
            },
            {
              desc: "Code",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "52",
          conv: "MD4",
          dict_name: "TAX.RATE",
          desc_items: [
            {
              desc: "Tax",
            },
            {
              desc: "Rate",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "53",
          dict_name: "RESALE",
          desc_items: [
            {
              desc: "Resale",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "54",
          dict_name: "SHIP.VIA",
          desc_items: [
            {
              desc: "Ship.Via",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "55",
          dict_name: "FROM.SHIP",
          desc_items: [
            {
              desc: "From",
            },
            {
              desc: "Ship",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "56",
          conv: "MD2",
          dict_name: "TOTAL.SALES.TAX",
          desc_items: [
            {
              desc: "Total",
            },
            {
              desc: "Sales.Tax",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "57",
          conv: "MD2",
          dict_name: "TOTAL.TAXABLE",
          desc_items: [
            {
              desc: "Total",
            },
            {
              desc: "Taxable",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "58",
          conv: "D2-",
          dict_name: "REG.DATE",
          desc_items: [
            {
              desc: "Reg.Date",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "59",
          conv: "MD2",
          dict_name: "REG.INVOICE.AMT",
          desc_items: [
            {
              desc: "Reg.Invoice.Amt",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "60",
          conv: "MCU",
          dict_name: "REG.ID",
          desc_items: [
            {
              desc: "Reg.Id",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "61",
          conv: "D2-",
          dict_name: "PRINT.DATE",
          desc_items: [
            {
              desc: "Print",
            },
            {
              desc: "Date",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "62",
          conv: "MCU",
          dict_name: "CO.CODE",
          desc_items: [
            {
              desc: "Co.Code",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "63",
          dict_name: "SHIP.CITY",
          desc_items: [
            {
              desc: "Ship.City",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "64",
          dict_name: "SHIP.STATE",
          desc_items: [
            {
              desc: "Ship.State",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "65",
          dict_name: "SHIP.ZIP",
          desc_items: [
            {
              desc: "Ship.Zip",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "66",
          dict_name: "SHIP.GEOCODE",
          desc_items: [
            {
              desc: "Ship.Geocode",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "67",
          conv: "MD2",
          dict_name: "LI.SALES.TAX",
          desc_items: [
            {
              desc: "Li.Sales.Tax",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "68",
          dict_name: "VTXREG.NBR",
          desc_items: [
            {
              desc: "Vtxreg",
            },
            {
              desc: "Nbr",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "69",
          dict_name: "FROM.RMA",
          desc_items: [
            {
              desc: "From",
            },
            {
              desc: "Rma",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "70",
          conv: "MCU",
          dict_name: "PROJECT.ID",
          desc_items: [
            {
              desc: "Project.Id",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "71",
          dict_name: "TASK.ID",
          desc_items: [
            {
              desc: "Task.Id",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "72",
          dict_name: "SHIP.ADDRESS",
          desc_items: [
            {
              desc: "Ship.Address",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "73",
          conv: "MD0",
          dict_name: "PRICE.PER",
          desc_items: [
            {
              desc: "Price",
            },
            {
              desc: "Per",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "74",
          dict_name: "SHIP.SEQ.NO",
          desc_items: [
            {
              desc: "Ship.Seq",
            },
            {
              desc: "No",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "75",
          dict_name: "COST.GROUP",
          desc_items: [
            {
              desc: "Cost",
            },
            {
              desc: "Group",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "76",
          dict_name: "MISC.REBATE",
          desc_items: [
            {
              desc: "Misc.Rebate",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "77",
          dict_name: "SHIP.COUNTRY",
          desc_items: [
            {
              desc: "Ship.Country",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "101",
          dict_name: "CREDITCARD.NO",
          desc_items: [
            {
              desc: "Creditcard.No",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "102",
          dict_name: "CREDITCARD.TYPE",
          desc_items: [
            {
              desc: "Creditcard.Type",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "103",
          dict_name: "CARDHOLDER.NAME",
          desc_items: [
            {
              desc: "Cardholder.Name",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "104",
          dict_name: "CREDITCARD.EXP",
          desc_items: [
            {
              desc: "Creditcard.Exp",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "105",
          dict_name: "CREDITCARD.CODE",
          desc_items: [
            {
              desc: "Creditcard.Code",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "106",
          dict_name: "CREDITCARD.TRAN",
          desc_items: [
            {
              desc: "Creditcard.Tran",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "107",
          conv: "MD2",
          dict_name: "CREDITCARD.AMT",
          desc_items: [
            {
              desc: "Creditcard.Amt",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "109",
          dict_name: "REBATE.TYPE",
          desc_items: [
            {
              desc: "Rebate.Type",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "110",
          conv: "MD2",
          dict_name: "REBATE.PCT",
          desc_items: [
            {
              desc: "Rebate",
            },
            {
              desc: "Percent",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "111",
          dict_name: "REBATE.FREQ",
          desc_items: [
            {
              desc: "Rebate.Freq",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "112",
          conv: "MCU",
          dict_name: "REBATE.ACCT",
          desc_items: [
            {
              desc: "Rebate.Acct",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "113",
          conv: "MCU",
          dict_name: "SERV.CHG.AR",
          desc_items: [
            {
              desc: "Service.Charge",
            },
            {
              desc: "Invoice",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "114",
          conv: "D2-",
          dict_name: "SERV.CHG.DATE",
          desc_items: [
            {
              desc: "Service.Charge",
            },
            {
              desc: "Date",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "115",
          conv: "MD2",
          dict_name: "SERV.CHG.AMT",
          desc_items: [
            {
              desc: "Service.Charge",
            },
            {
              desc: "Amount",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "116",
          dict_name: "SERV.CHARGE.INV",
          desc_items: [
            {
              desc: "Service.Charge",
            },
            {
              desc: "Invoice",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "117",
          conv: "MCU",
          dict_name: "COUPON.ID",
          desc_items: [
            {
              desc: "Coupon.Id",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "118",
          conv: "MD2",
          dict_name: "COUPON.AMT",
          desc_items: [
            {
              desc: "Coupon.Amt",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "119",
          dict_name: "CC.ADDR",
          desc_items: [
            {
              desc: "Cc.Addr",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "120",
          dict_name: "CC.CITY",
          desc_items: [
            {
              desc: "Cc.City",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "121",
          dict_name: "CC.STATE",
          desc_items: [
            {
              desc: "Cc.State",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "122",
          dict_name: "CC.ZIP",
          desc_items: [
            {
              desc: "Cc.Zip",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "123",
          dict_name: "CC.COUNTRY",
          desc_items: [
            {
              desc: "Cc.Country",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "124",
          dict_name: "CC.ERROR",
          desc_items: [
            {
              desc: "Cc.Error",
            },
          ],
          just: "T",
          json_name: "",
        },
        {
          field_no: "125",
          dict_name: "CURRENCY.CODE",
          desc_items: [
            {
              desc: "Currency",
            },
            {
              desc: "Code",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "126",
          conv: "MD4",
          dict_name: "EXCHANGE.RATE",
          desc_items: [
            {
              desc: "Exchange",
            },
            {
              desc: "Rate",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "127",
          dict_name: "COLLECT.STATUS",
          desc_items: [
            {
              desc: "Collect.Status",
            },
          ],
          just: "L",
          index: "Y",
          json_name: "",
        },
        {
          field_no: "128",
          conv: "D2-",
          dict_name: "NEXT.DATE",
          desc_items: [
            {
              desc: "Next.Date",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "135",
          conv: "MCU",
          dict_name: "FRT.TAXABLE",
          desc_items: [
            {
              desc: "Freight",
            },
            {
              desc: "Taxable",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "136",
          dict_name: "CREDIT.CARD.ID",
          desc_items: [
            {
              desc: "Credit.Card.Id",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "137",
          conv: "D2-",
          dict_name: "TAX.DATE",
          desc_items: [
            {
              desc: "Tax.Date",
            },
          ],
          just: "R",
          index: "N",
          json_name: "",
        },
        {
          field_no: "138",
          dict_name: "FROM.PSO",
          desc_items: [
            {
              desc: "From.Pso",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "139",
          dict_name: "REGISTER.NO",
          desc_items: [
            {
              desc: "Register.No",
            },
          ],
          just: "L",
          index: "N",
          json_name: "",
        },
        {
          field_no: "140",
          dict_name: "ACH.PENDING",
          desc_items: [
            {
              desc: "Ach.Pending",
            },
          ],
          just: "L",
          json_name: "",
        },
        {
          field_no: "141",
          conv: "MD2",
          dict_name: "CC.CONV.FEE",
          desc_items: [
            {
              desc: "Cc.Conv.Fee",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "142",
          conv: "MCU",
          dict_name: "PREPAID.CASH.ID",
          desc_items: [
            {
              desc: "Prepaid.Cash.Id",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "143",
          conv: "MCU",
          dict_name: "PREPAID.SO.ID",
          desc_items: [
            {
              desc: "Prepaid.So.Id",
            },
          ],
          just: "R",
          json_name: "",
        },
        {
          field_no: "144",
          dict_name: "DISC.DESC",
          desc_items: [
            {
              desc: "Disc.Desc",
            },
          ],
          just: "L",
          required: "N",
          json_name: "",
        },
      ],
      correl_field_no_items: [],
    },
  ],
};
